// Http Communication Get, post, put , delete...
// axios library
import axios from "axios";
import { callApi } from "./api";
export const getFullRoute = (pathname) => {
  const basicRoute = callApi();
  pathname = pathname.replace("runbookService", basicRoute);
  return pathname;
};
export const GetApi = async (method, pathname) => {
  try {
    const route = getFullRoute(pathname);
   
    const response = await axios[method](route);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const MethodApi = async ({method, pathname, body , onSuccess, onError}) => {
  try {
    const route = getFullRoute(pathname);
    const response = await axios[method](route,body);
    onSuccess(response.data);
  } catch (error) {
    onError(error?.response?.data);
  }
};
