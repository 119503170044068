import React from "react";
import Evaluate from "../../component/evaluate/evaluate.jsx";


import "./index.css";

const Index = () => {
  
  return (
    <Evaluate
    />
  );
};

export default Index;
