import React, { useState } from "react";
import "./index.css";
import Cves from "../../component/cves/cves";
import { useEffect } from "react";
import { GetApi } from "../../service/http";
const Index = () => {
  const [data , setData] = useState();
  const fetchData = async () => {
    const result = await GetApi(
      "get",
      `runbookService/api/v1/store?vendor=${'AWS'}&category`
    );
   
    setData(result);
    
  };
  useEffect(()=>{
    fetchData();
  },[])
  return <Cves data={data}/> ;
};

export default Index;
