import React from "react";
import "./policy.css";
import { Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
const Policyv1 = ({ data }) => {
  const navigate = useNavigate();
  const customOrder = ["Identify", "Protect", "Detect", "Respond", "Recover"];

  const mockData = Object.keys(data?.stats || {}).sort((a, b) => {
    return customOrder.indexOf(a) - customOrder.indexOf(b);
  });
  const policyCategoryHandler = (categoryid, categoryname) => {
    navigate(`/policy/${categoryid}?categoryname=${categoryname}`);
  };
  const calculatePoliciesSum = (category) => {
    return data?.stats[category].reduce(
      (sum, entry) => sum + entry.policies,
      0
    );
  };
  return (
    <Row className="policy-card-container">
      {mockData.map((policyCategory, index) => (
        <Card
          key={index}
          title={
            <Row className="font-secondary">
              <Col
                span={12}
                align="left"
                style={{ display: "flex", alignItems: "center" }}
              >
                <p
                  className={`summary-category category-options-${policyCategory}`}
                >
                  {policyCategory}
                </p>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <p className="text-sm lg:text-xs mr-3">
                  {calculatePoliciesSum(policyCategory)}
                </p>
                <span className="text-sm lg:text-xs">Policies</span>
              </Col>
            </Row>
          }
          className="policy-card bg-secondary"
        >
          {data?.stats[policyCategory].map((policy, index) => (
            <Row key={index} className="font-default p-1">
              <Col span={12} align="left">
                <p
                  className="text-sm  lg:text-xs lh-1"
                  style={{
                    color: "rgb(1 100 127)",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    policyCategoryHandler(
                      policy.categoryid,
                      policy.categoryname
                    )
                  }
                >
                  {policy.categoryname}
                </p>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <p className="text-sm lg:text-xs mr-3">{policy.policies} </p>
              </Col>
            </Row>
          ))}
        </Card>
      ))}
    </Row>
  );
};

export default Policyv1;
