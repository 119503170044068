import { useParams } from 'react-router-dom';
import Control from '../../component/policy_Control/control'
import React, { useEffect, useState } from 'react'
import { GetApi } from '../../service/http';

const Index = () => {
    const [data,setData ] = useState();
    const params = useParams();
    const fetchData = async(controlId)=>{
      const result = await GetApi("get", `runbookService/api/v1/control/${controlId}`);
      setData(result)
    }
    useEffect(() => {
      
      fetchData(params.controlId);
      // eslint-disable-next-line
    }, []);
    const handlePillClick =(controlId)=>{
        const trimSpace = controlId.trim();
        fetchData(trimSpace)
    }
  return (
    <Control data={data} handlePillClick={handlePillClick}/>
  )
}

export default Index
