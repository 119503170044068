import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./breadcrumb.css";
import { getBreadcrumbCategoryValue } from "../../utils";
import { Row } from "antd";
const BreadcrumbComponent = ({ runBookName,category, categoryName, policyName , taskname , taskstatus ,subcategoryname ,controlname }) => {
  const location = useLocation();
  const params = useParams();
  const itemsLocation = [
    {
      
      path: `/installrunbook/${params?.vendorcode}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/installrunbook`}> Installed Runbooks </Link>
          <p> / </p>
          {params?.vendorcode}
        </Row>
      ),
    },
    {
      path: `/runbookstore/${params?.vendorcode}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/runbookstore`}> Runbook Store </Link>
          <p> / </p>
          {params?.vendorcode}
        </Row>
      ),
    },
    {
      path: "/installrunbook",
      label: "Installed Runbooks",
    },
    {
      path: "/",
      label: "My Dashboard",
    },
    {
      path: "/runbookstore",
      label: "Runbook Store",
    },
    {
      path: "/nist_csf",
      label: "Cybersecurity Framework",
    },
    {
      path: `/nist_csf/${params?.categoryId}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/nist_csf`}> Cybersecurity Framework</Link>
          <p> / </p>
          {category}
        </Row>
      ),
    },
    {
      path: `/nist_csf/${params?.categoryId}/${params.subcategoryId}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/nist_csf`}> Cybersecurity Framework</Link>
          <p> / </p>
          <Link to={`/nist_csf/${params?.categoryId}?category=${category}`}>  {category} </Link>
          <p> / </p>
          {params?.subcategoryId}
        </Row>
      ),
    },
    {
      path: `/nist_csf/${params?.categoryId}/${params.subcategoryId}/${params?.id}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/nist_csf`}> Cybersecurity Framework</Link>
          <p> / </p>
          <Link to={`/nist_csf/${params?.categoryId}?category=${category}`}> {category}</Link>
          <p> / </p>
          <Link to={`/nist_csf/${params?.categoryId}/${params?.subcategoryId}?category=${category}`}>{params?.subcategoryId}</Link>
          <p> / </p>
          {runBookName}
        </Row>
      ),
    },
    {
      path: `/nist_csf/${params?.categoryId}/${params.subcategoryId}/policy/${params?.policyDescriptionId}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/nist_csf`}> Cybersecurity Framework</Link>
          <p> / </p>
          <Link to={`/nist_csf/${params?.categoryId}?category=${category}`}> {category}</Link>
          <p> / </p>
          <Link to={`/nist_csf/${params?.categoryId}/${params?.subcategoryId}?category=${category}`}>{params?.subcategoryId}</Link>
          <p> / </p>
          {policyName}
        </Row>
      ),
    },
    {
      path: "/cves",
      label: "CVEs",
    },
    {
      path: "/evaluate",
      label: "Evaluate",
    },
    {
      path: "/Evaluate",
      label: "Evaluate",
    },
    {
      path: "/policy",
      label: " Cybersecurity Framework Policies",
    },
    {
      path: `/policy/${params?.id}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/policy`}> Cybersecurity Framework Policies </Link>
          <p> / </p>

          {getBreadcrumbCategoryValue(params?.id?.split(".")[0])}
        </Row>
      ),
    },
    {
      path: `/policy/${params?.policyCategoryId}/${params?.policyDetailId}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/policy`}> Cybersecurity Framework Policies </Link>
          <p> / </p>
          <Link
            to={`/policy/${params?.policyCategoryId}?categoryname=${categoryName}`}
          >
            {getBreadcrumbCategoryValue(
              params?.policyCategoryId?.split(".")[0]
            )}
          </Link>
          <p>/</p>
          {params?.policyDetailId}
        </Row>
      ),
    },
    {
      path: `/policy/${params?.policyCategoryId}/${params?.policyDetailId}/control/${params?.controlId}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/policy`}> Cybersecurity Framework Policies </Link>
          <p> / </p>
          <Link
            to={`/policy/${params?.policyCategoryId}?categoryname=${categoryName}`}
          >
            {getBreadcrumbCategoryValue(
              params?.policyCategoryId?.split(".")[0]
            )}
          </Link>
          <p>/</p>
          <Link
            to={`/policy/${params?.policyCategoryId}/${params?.policyDetailId}?categoryname=${categoryName}`}
          > {params?.policyDetailId} </Link>
          <p>/</p>
          {controlname}
        </Row>
      ),
    },
    {
      path: `/policy/${params?.policyCategoryId}/${params?.policyDetailId}/${params?.policyDescriptionId}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/policy`}> Cybersecurity Framework Policies </Link>
          <p> / </p>
          <Link
            to={`/policy/${params?.policyCategoryId}?categoryname=${categoryName}`}
          >
            {getBreadcrumbCategoryValue(
              params?.policyCategoryId?.split(".")[0]
            )}
          </Link>
          <p>/</p>
          <Link
            to={`/policy/${params?.policyCategoryId}/${params?.policyDetailId}?categoryname=${categoryName}`}
          >
            {" "}
            {params?.policyDetailId}
          </Link>
          <p>/</p>
          {policyName}
        </Row>
      ),
    },
    {
      path: `/installrunbook/${params?.vendorcode}/${params?.id}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/installrunbook`}> Installed Runbook </Link>
          <p> / </p>
          <Link to={`/installrunbook/${params?.vendorcode}`}>
            {params?.vendorcode}
          </Link>
          <p>/</p>
          {runBookName}
        </Row>
      ),
    },
    {
      path: `/runbookstore/${params?.vendorcode}/${params?.id}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/runbookstore`}> Runbook Store </Link>
          <p> / </p>
          <Link to={`/runbookstore/${params?.vendorcode}`}>
            {params?.vendorcode}
          </Link>
          <p>/</p>
          {runBookName}
        </Row>
      ),
    },
    {
      path: `/policy/${params?.policyCategoryId}/${params?.policyDetailId}/runbook/${params?.id}`,
      label: (
        <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
          <Link to={`/policy`}> Cybersecurity Framework Policies </Link>
          <p> / </p>
          <Link
            to={`/policy/${params?.policyCategoryId}?categoryname=${categoryName}`}
          >
            {getBreadcrumbCategoryValue(
              params?.policyCategoryId?.split(".")[0]
            )}
          </Link>
          <p>/</p>
          <Link
            to={`/policy/${params?.policyCategoryId}/${params?.policyDetailId}?categoryname=${categoryName}`}
          >
            {" "}
            {params?.policyDetailId}
          </Link>
          <p>/</p>
          {runBookName}
        </Row>
      ),
    },{
      path: "/task",
      label: "Tasks",
    },
    {
      path: `/task/${params.taskid}`,
      label:   <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
      <Link to={`/task`}> Tasks </Link>
      <p> / </p>

      {taskname}
    </Row>
    },
    {
      path: `/task/template`,
      label:   <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
      <Link to={`/task`}> Tasks </Link>
      <p> / </p>
      {"Template"}
    </Row>
    },
    {
      path: `/task/${params.taskid}/${params.id}`,
      label:   <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
      <Link to={`/task`}> Tasks </Link>
      <p> / </p>
      <Link to={`/task/${params?.taskid}?taskname=${taskname}&taskstatus=${taskstatus}`}> {taskname} </Link>
      <p> / </p>
      {runBookName}
    </Row>
    },
    {
      path: `/task/template/${params.id}`,
      label:   <Row className="font-sans text-xl md:text-base" style={{ gap: "8px" }}>
      <Link to={`/task?tab=2`}>Template </Link>
      <p> / </p>
      {runBookName}
    </Row>
    },
    {
      path: "/governance",
      label: "Governance",
    },
    {
      path: "/audit",
      label: "Audit",
    },
  ];

  return (
    <div>
      {itemsLocation.map((item, index) => (
        <div key={index}>
          {item.path === location.pathname && (
            <p className="text-xl md:text-base font-sans breadcrum">
              {item?.label}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadcrumbComponent;