import React from "react";
import Task from "../../component/task/task.jsx";

import { useState } from "react";

import "./index.css";

import { useEffect } from "react";
import { GetApi } from "../../service/http";

const Index = ({ tasks, formHandler }) => {
  const [data, setData] = useState();
  const fetchData = async () => {
    const result = await GetApi("get", `runbookService/api/v1/runbook/1`);

    result?.runbook?.processes?.splice(0, 0, {
      name: "Goal",
      goal: result?.runbook?.goal,
      impact: result?.runbook?.impact,
    });
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const processes = data?.runbook?.runbookProcessList?.map((process) => ({
    processnumber: process.processNumber,
    processname: process.processName,
   
  }));

  const subProcesses = data?.runbook?.runbookProcessList?.flatMap((process) =>
    process.subProcesses.map((subProcess) => ({
      processnumber: subProcess?.processNumber,
      processname: subProcess?.processName,
      
    }))
  );

  // Merging both arrays
  const allProcesses = processes?.concat(subProcesses);

  return (
    <Task
      tasks={tasks}
      processes={allProcesses}
      formHandler={(values) => formHandler(values)}
    />
  );
};

export default Index;
