import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, TreeSelect, message, Table, Row, Col, Space, Popconfirm, Spin } from 'antd';
import { taskMockData } from '../../mockdata/mockJson';
import './taskSurvey.css';
import { SearchOutlined } from "@ant-design/icons";
import { GetApi } from "../../service/http";
import ViewTemplateModal from "./viewTemplateModal"
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
const { Option, OptGroup } = Select;
const { TextArea } = Input;

const TaskSurvey = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState(taskMockData.taskTemplates);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDummyTemplate, setIsDummyTemplate] = useState(false);
  const [form] = Form.useForm();
  const [vendorData, setVendorData] = useState({});
  const [runbookMap, setRunbookMap] = useState({}); // Map to store runbook data for easy lookup
  const [loading, setLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [runbookSections, setRunbookSections] = useState([]);

  // Fetch vendor data from the API
  const fetchVendorData = async () => {
    const fetchedData = {};
    const runbookLookup = {};
    const vendors = ["AWS", "GCP", "AZ", "OCI"];
    setLoading(true);
    for (const vendor of vendors) {
      try {
        const result = await GetApi("get", `runbookService/api/v1/runbook?vendor=${vendor}&category`);
        result.runbooks = result.runbooks || []
        result.runbooks = result?.runbooks?.sort((a, b) => new Date(b.date) - new Date(a.date)) || [];
        fetchedData[vendor] = result.runbooks;
        result.runbooks.forEach(runbook => {
          runbookLookup[runbook.id] = runbook.name; // Store runbook data in the lookup map
          runbook.vendorCode = vendor;
        });
      } catch (error) {
        console.error(`Error fetching data for vendor ${vendor}:`, error);
      } finally {
        //setLoading(false);
      }
    }
    setVendorData(fetchedData);
    setRunbookMap(runbookLookup); // Update state with runbook lookup map
  };

  useEffect(() => {
    fetchVendorData();
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchVendorData();
    const query = new URLSearchParams(location.search);
    const templateId = query.get('templateId');
    if (templateId) {
      const template = taskMockData.dummyTemplates.find(t => t.templateId === templateId);
      
      if (template) {
        setCurrentTemplate(null);
        setIsDummyTemplate(true);
        handleEditTemplate(template);
      }
    }
    setLoading(true);
  }, [location]);
const reset = ()=>{
  setIsDummyTemplate(false)
  const params = new URLSearchParams(location.search);
    params.delete('templateId');
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    }, { replace: true });
}
  const fetchProceduralSteps = async (runbookId) => {
    // setLoading(true);
    try {
      const response = await GetApi("get", `runbookService/api/v1/runbook/${runbookId}`);
      const processes = response.runbook?.runbookProcessList || [];
      return flattenProcesses(processes);
    } catch (error) {
      console.error("Error fetching procedural steps:", error);
      return [];
    } finally {
     // setLoading(false);
    }
  };

  function flattenProcesses(processList) {
    let flattenedList = [];
    function recurse(process, parentKey = '') {
      const processKey = parentKey ? `${parentKey}.${process.processNumber}` : process.processNumber;
      flattenedList.push({ ...process, key: processKey });
      if (process.subProcesses && process.subProcesses.length > 0) {
        process.subProcesses.forEach(subProcess => recurse(subProcess, processKey));
      }
    }
    processList.forEach(process => recurse(process));
    return flattenedList;
  }

  const mapFormToSteps = (formValues, runbookId, runbookName) => {
    return formValues.map(value => {
      const [processNumber, stepIndex] = value.split('-');
      const rnbk = runbookSections.find(p => p.runbookId === runbookId)
      const process = runbookSections.find(p => p.runbookId === runbookId)?.stepsData.find(p => p.key === processNumber);
      const step = process?.proceduralSteps[stepIndex - 1] || {};
      return {
        runbookid: runbookId,
        runbookname: runbookName,
        vendorCode :rnbk.vendorCode,
        processNumber: process?.processNumber || "1.1",
        processName: process?.processName || '',
        proceduralStepDescription: step.description,
        stepIndex: parseInt(stepIndex, 10),
        updatedAt: '',
        reviewerupdatedAt: ''
      };
    });
  };

  const handleAddTemplate = () => {
    setCurrentTemplate(null);
    form.resetFields();
    setRunbookSections([]);
    setIsReadOnly(false);
    setIsModalOpen(true);
    setLoading(false)
  };

  const handleModalOk = async () => {
    form.validateFields().then(values => {
      const ownerSteps = runbookSections.flatMap(section => mapFormToSteps(section.ownerSteps, section.runbookId, section.runbookName));
      const newTemplate = {
        ...values,
        templateId: currentTemplate ? currentTemplate.templateId : Date.now().toString(),
        ownerSteps,
        runbooks: runbookSections.map(section => ({ id: section.runbookId, name: section.runbookName, vendorCode:section.vendorCode  })),
        createdAt: currentTemplate ? currentTemplate.createdAt : moment().format('YYYY-MM-DD')
      };
      if (isDummyTemplate){
        newTemplate.templateId = Date.now().toString();
        newTemplate.createdAt = moment().format('YYYY-MM-DD');
      }

      let updatedTemplates;
      if (currentTemplate && !isDummyTemplate) {
        updatedTemplates = templates.map(t => t.templateId === currentTemplate.templateId ? newTemplate : t);
      } else {
        updatedTemplates = [...templates, newTemplate];
      }

      setTemplates(updatedTemplates);
      taskMockData.updateTaskTemplates(updatedTemplates);

      setIsModalOpen(false);
      form.resetFields();
      reset();
    }).catch((errorInfo) => {
      message.error('Please fill out all required fields correctly.');
    });;
  };

  const addRunbookSection = () => {
    setRunbookSections([...runbookSections, { runbookId: '', runbookName: '', ownerSteps: [], stepsData: [] }]);
  };

  const handleRunbookChange = async (value, index) => {
    const newRunbookSections = [...runbookSections];
    setLoading(true);
    setRunbookSections(newRunbookSections);
    const allRunbooks = Object.values(vendorData).flat().flatMap(vendor => vendor);
    const runbook = allRunbooks.find(r => r.id === value);
    if (runbook) {
      const stepsData = await fetchProceduralSteps(value);
      newRunbookSections[index] = {
        runbookId: value,
        runbookName: runbook.name,
        vendorCode: runbook.vendorCode,
        stepsData: flattenProcesses(stepsData),
        ownerSteps: [],
      };
      setRunbookSections(newRunbookSections);
    } else {
      message.error("Runbook not found.");
    }
    setLoading(false);
  };

  const handleOwnerStepsChange = (value, index) => {
    const newRunbookSections = [...runbookSections];
    newRunbookSections[index].ownerSteps = value;
    setRunbookSections(newRunbookSections);
  };

  const handleViewTemplate = async (template) => {
    setLoading(true)
    setCurrentTemplate(template);
    setIsViewModalOpen(true);
    setTimeout(() => {
      setLoading(false)
    }, 500);
    
  };

  const handleEditTemplate = async (template) => {
    setCurrentTemplate(template);
    setIsReadOnly(false);
    setIsModalOpen(true);
    setLoading(true);
    const runbookSectionsPromises = template.runbooks.map(async runbook => {
      const stepsData = await fetchProceduralSteps(runbook.id);
      return {
        runbookId: runbook.id,
        runbookName: runbookMap[runbook.id], // Map ID to name
        stepsData: flattenProcesses(stepsData),
        ownerSteps: template.ownerSteps.filter(step => step.runbookid === runbook.id).map(step => `${step.processNumber}-${step.stepIndex}`),
      };
    });
    

    const runbookSections = await Promise.all(runbookSectionsPromises);
    setRunbookSections(runbookSections);

    form.setFieldsValue({
      ...template,
      runbooks: runbookSections.map(runbook => ({
        value: runbook.runbookId,
        label: runbook.runbookName
      }))
    });
    setTimeout(() => {
      setLoading(false);
    }, 300);
    
  };

  const handleDeleteTemplate = (templateId) => {
    const updatedTemplates = templates.filter(t => t.templateId !== templateId);
    setTemplates(updatedTemplates);
    taskMockData.updateTaskTemplates(updatedTemplates);
    message.success('Template deleted successfully');
  };

  const transformToTreeData = (processList, parentKey = '', isTopLevel = true) => {
    return processList.map((process) => {
      const processKey = parentKey ? `${parentKey}.${process.processNumber}` : process.processNumber;
      const children = [];

      if (process.subProcesses) {
        children.push(
          ...transformToTreeData(process.subProcesses, "", false).map(subProcess => ({
            ...subProcess,
            disabled: true,
          }))
        );
      }

      if (process.proceduralSteps && process.proceduralSteps.length > 0) {
        children.push(
          ...process.proceduralSteps.map((step, stepIndex) => ({
            title: `${process.processNumber}.${stepIndex + 1} ${step.description}`,
            value: `${processKey}-${stepIndex + 1}`, 
            key: `${processKey}-${stepIndex + 1}`,
            disabled: false,
          }))
        );
      }

      return {
        title: (
          <span style={isTopLevel ? { fontWeight: 'bold' } : {}}>
            {`${process.processNumber}. ${process.processName}`}
          </span>
        ),
        value: processKey,
        key: processKey,
        disabled: isTopLevel,
        children,
      };
    });
  };


  // Define columns for the table
  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'templateName',
      width: 350,
      key: 'templateName',
      render: (text, record) => (
        <span
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: "rgb(1 100 127)",
          }}
          onClick={() => handleViewTemplate(record)}
        >{text}</span>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 500,
      key: 'description',
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 100,
      render: () => "Lisa Audrey"
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (text) => moment(text).format('MMMM DD, YYYY')
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditTemplate(record)}>Edit</Button>
          <Popconfirm
            title="Are you sure to delete this template?"
            onConfirm={() => handleDeleteTemplate(record.templateId)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="__no">
      <Row justify="start" align="middle" style={{ marginBottom: 16, gap: "10px" }}>
        <Col>
          <Input
            className="search-tasks"
            addonBefore={<SearchOutlined />}
            placeholder="Search By Name"
            onChange={e => {
              setTemplates(taskMockData.taskTemplates.filter(template =>
                template.templateName.toLowerCase().includes(e.target.value.toLowerCase())
              ));
            }}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleAddTemplate}>Add Template</Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={templates} rowKey="templateId" />
      <ViewTemplateModal
        template={currentTemplate}
        visible={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
      />
        <Modal
          title={currentTemplate ? (isReadOnly ? 'View Template' : isDummyTemplate ? 'Add New Template' : 'Edit Template') : 'Add Template'}
          visible={isModalOpen}
          onOk={isReadOnly ? () => {setIsModalOpen(false); reset()} : handleModalOk}
          onCancel={() => { setIsModalOpen(false); reset();}}
          footer={
            isReadOnly ? (
              <Button type="primary" onClick={() => setIsModalOpen(false)}>Close</Button>
            ) : (
              <Button type="primary" onClick={handleModalOk}>Submit</Button>
            )
          }
        >
          <Spin spinning={loading}>
             
          <Form form={form} layout="vertical">
            <Form.Item
              name="templateName"
              label="Template Name"
              rules={[{ required: true, message: 'Please enter the template name' },
                {
                  validator: (_, value) => {
                    if (!value || templates.every(t => t.templateName.toLowerCase() !== value.toLowerCase() || (currentTemplate && t.templateId === currentTemplate.templateId))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Template name already exists'));
                  }
                }
              ]}
            >
              <Input readOnly={isReadOnly} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please enter the description' }]}
            >
              <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 10 }} readOnly={isReadOnly} />
            </Form.Item>
            <Form.Item
              name="hrcLevel"
              label="HRC Level"
              rules={[{ required: true, message: 'Please enter the description' }]}
            >
            <Select placeholder="Select HRC Level">
            <Option value="0">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>0</span>
                  <span style={{ marginLeft: '7px' }}>- No HRC</span>
                </span>
              </Option>
              <Option value="1">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>1</span>
                  <span style={{ marginLeft: '7px' }}>- HRC Low</span>
                </span>
              </Option>
              <Option value="2">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>2</span>
                  <span style={{ marginLeft: '7px' }}>- HRC High</span>
                </span>
              </Option>
              <Option value="3">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>3</span>
                  <span style={{ marginLeft: '7px' }}>- HRC Very High</span>
                </span>
              </Option>
            </Select>
            </Form.Item>
            <Form.Item
            name="awsAccount"
            label="Account Information"
            rules={[{ required: false, message: 'Please enter the account information' }]}
          >
            <Input readOnly={isReadOnly} />
          </Form.Item>
          <Form.Item
            name="awsS3URI"
            label="S3 Bucket Object Resource URI"
            rules={[{ required: false, message: 'Please enter the S3 bucket object resource URI' }]}
          >
            <Input readOnly={isReadOnly} />
            </Form.Item>
            {runbookSections.map((section, index) => (
              <div key={index} style={{ marginBottom: 24 }}>
                <Form.Item
                  label={`Runbook ${index + 1}`}
                  rules={[{ required: true, message: 'Please select a runbook' }]}
                >
                  <Select
                    placeholder="Select a runbook"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())}
                    onChange={(value) => handleRunbookChange(value, index)}
                    value={section.runbookId}
                  >
                    {Object.keys(vendorData).map((vendor) => (
                      <OptGroup label={vendor} key={vendor}>
                        {vendorData[vendor].map((runbook) => (
                          <Option key={runbook.id} value={runbook.id}>
                            {runbook.name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Procedural Steps for Operator"
                  rules={[{ required: true, message: 'Please select procedural steps for the operator' }]}
                >
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    value={section.ownerSteps}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select procedural steps for the operator"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    loading={loading}
                    treeData={transformToTreeData(section.stepsData)}
                    onChange={(value) => handleOwnerStepsChange(value, index)}
                  />
                </Form.Item>
              </div>
            ))}
            <Button type="dashed" onClick={addRunbookSection} style={{ width: '100%', marginBottom: 24 }}>
              + Select  Runbook(s)
            </Button>
          </Form>
          </Spin>
        </Modal>
    </div>
  );
};

export default TaskSurvey;
