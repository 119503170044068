import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Row,
  Space,
  Spin,
  Table,
  notification,
  Popover,
  Modal,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import "./runbookStore.css";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { DownloadOutlined, ShoppingOutlined } from "@ant-design/icons";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import GenreicModal from "../modal/importModal";
import { MethodApi } from "../../service/http";
import { useNavigate, useParams } from "react-router-dom";
const RunbookStore = ({
  data,
  categoryData,
  categoryFilter,
  getApiHandler,
}) => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [runbookID, setRunbookID] = useState();
  const [searchText, setSearchText] = useState("");
  const params = useParams();

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
      align: "left",
      render: (text, record) => (
        <Space
          size="middle"
          onClick={() => RunbookDetailHandler(record?.id, record?.installed)}
        >
          <p>
            {" "}
            <p
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "rgb(1 100 127)",
              }}
            >
              {text}
            </p>{" "}
          </p>
        </Space>
      ),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      align: "center",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 150,
    },
    // {
    //   title: "CVEs ",
    //   dataIndex: "cves",
    //   key: "address",
    //   align: "center",
    //   width: 150,
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <p>{record.id===22 ?1 : 0}</p>
    //     </Space>
    //   ),
    // },
    {
      title: "Processes",
      dataIndex: "processes",
      key: "processes",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts?.processes}</p>
        </Space>
      ),
    },
    {
      title: "NIST CSF Policies ",
      dataIndex: "policies",
      key: "nist_csf_policies",
      align: "center",
      width: 120,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts?.policies}</p>
        </Space>
      ),
    },
    {
      title: "NIST CSF Subcategories",
      dataIndex: "subcategories",
      key: "nist_csf_sub_categories",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts?.subcategories}</p>
        </Space>
      ),
    },
    {
      title: "NIST 800-53 Controls",
      dataIndex: "controls",
      key: "nist_800_controls",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts?.controls}</p>
        </Space>
      ),
    },
    {
      title: "",
      key: "download",
      align: "center",
      width: 120,
      render: (text, record) => (
        <Space size="middle">
          {record?.id === runbookID && loading && (
            <Spin spinning={loading}></Spin>
          )}
          {!loading && <>
      {record?.installed ? (
        <Popover title="Purchase runbook">
        <ShoppingOutlined
          style={{
            fontSize: "25px",
            cursor: "not-allowed",
            color: 'gray'
          }}
        />
        </Popover>
      ) : (
        <Popover title="Purchase runbook">
          <ShoppingOutlined
            style={{
              fontSize: "25px",
              cursor: "pointer",
              color:"blue"
            }}
          />
        </Popover>
      )}
    </>}
          {!loading && record?.installed === false && (
            <Popover title="Install runbook">
              <DownloadOutlined
                onClick={() =>
                  installRunbookHandler(record?.id, record?.installed)
                }
                title="Install"
                style={{
                  fontSize: "25px",
                  cursor: "pointer",
                }}
              />
            </Popover>
          )}
          {record?.installed === true && (
            <Popover title="Installed runbook">
              <CheckCircleOutlined
                title="Installed"
                style={{
                  fontSize: "25px",
                  color: "green",
                }}
              />
            </Popover>
          )}
          <Popover title="Edit runbook">
            <EditOutlined
              title="Edit"
              style={{
                fontSize: "25px",
                cursor: "not-allowed",
              }}
            />
          </Popover>
          <Popover title="Delete runbook">
            <DeleteOutlined
              title="Delete"
              onClick={() =>
                deleteRunbookHandler(record?.id, record?.installed)
              }
              disabled={record?.installed === true ? true : false}
              style={{
                fontSize: "25px",
                cursor: record.installed === true ? "not-allowed" : "pointer",
                color: record.installed === true ? "grey" : "red",
              }}
            />
          </Popover>
        </Space>
      ),
    },
  ];
  const RunbookDetailHandler = (id, isInstalled) => {
    navigate(`/runbookstore/${params?.vendorcode}/${id}?isInstalled=${isInstalled}`);
  };
  const installRunbookHandler = async (runbookId, isInstalled) => {
    setRunbookID(runbookId);
    setLoading(true);
    await MethodApi({
      method: "put",
      pathname: `runbookService/api/v1/store/${runbookId}`,
      onSuccess: () => {
        notification.success({
          message: "Runbook Installed",
          duration: 10,
        });
        getApiHandler();
      },
      onError: (error) => {
        notification.error({
          message: error?.message || "Install Runbook failed",
          duration: 2,
        });
        getApiHandler();
      },
    });
  };
  const deleteRunbookHandler = async (runbookId, isInstalled) => {
    if (isInstalled === false) {
      Modal.confirm({
        okText: "Delete",
        title: "Confirm Deletion",
        content: "Are you sure you want to delete this runbook?",
        onOk() {
          setRunbookID(runbookId);
          setLoading(true);
          MethodApi({
            method: "delete",
            pathname: `runbookService/api/v1/store/${runbookId}`,
            onSuccess: () => {
              setLoading(false);
              api["success"]({
                message: "Deleted Successfully",
                duration: 2,
              });
              getApiHandler();
            },
            onError: (error) => {
              api["error"]({
                message: error?.message || "Delete Runbook failed",
                duration: 2,
              });
              getApiHandler();
            },
          });
        },
        onCancel() {},
      });
    } else {
      api["info"]({
        message: "Uninstall this runbook to delete.",
        duration: 2,
      });
    }
  };

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    setCheckedItems(
      Array.from(
        { length: categoryData?.categories?.length },
        (_, index) => index + 1
      )
    );
  }, [categoryData?.categories]);
  const handleCheckboxChange = (index) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(index)) {
        return prevCheckedItems.filter((item) => item !== index);
      } else {
        return [...prevCheckedItems, index];
      }
    });
    categoryFilter(checkedItems);
  };

  const menu = (
    <Menu>
      {categoryData?.categories?.map((category, index) => (
        <Menu.Item key={index + 1}>
          <Checkbox
            value={index + 1}
            defaultChecked={true}
            onChange={() => handleCheckboxChange(index + 1)}
            disabled={true}
          >
            {category}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  const filtered = data?.runbooks?.filter((runbook) =>
    runbook.name.toLowerCase().includes(searchText.toLowerCase())
  );
  const importFinishHandler = () => {
    getApiHandler();
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);
  return (
    <div className="runbook-container">
      {contextHolder}
      <Row className="bg-priamry font-black runbook-header">
        <BreadcrumbComponent />
      </Row>
      <Row className="runbookStore-card-container">
        <Card className="runbookStoreCard">
          <p className="text-3xl">{data?.runbooks?.length || 0}</p>
          <p className="text-lh">Runbooks</p>
        </Card>
        {/* <Card className="runbookStoreCard">
          <p className="text-3xl" >
            {params?.vendorcode==="AWS" ? 1:0}
          </p>
          <p className="text-lh">CVEs</p>
        </Card> */}
      </Row>

      <Row className="p-2 mt-4 mb-4 import-search-container">
        <div className="import-search-container-left-side">
          <GenreicModal
            buttonText={"Import"}
            modalTitle={"Import"}
            categoryData={categoryData}
            importFinishHandler={importFinishHandler}
          />
          {/* <Button type="primary" className="btn-primary" disabled={true}>
            <p className="text-sm"> Generate with AI</p>
          </Button> */}
          <Button type="primary" className="btn-primary" disabled={true}>
            <p className="text-sm"> Create with wizard</p>
          </Button>
          <Input
            className="search-runbooks"
            addonBefore={<SearchOutlined />}
            placeholder="Search By Name"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {/* <Select style={{minWidth:"150px"}} placeholder="Resiliency Level">
            <Select.Option value={"low"}>LOW</Select.Option>
            <Select.Option value={"medium"}>MEDIUM</Select.Option>
            <Select.Option value={"high"}>HIGH</Select.Option>
          </Select> */}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="primary" className="btn-primary">
              <p className="text-sm"> Categories</p>
            </Button>
          </Dropdown>
          <Popover title="Export runbook(s)">
            <Button disabled={true} type="primary" className="btn-primary">
              <p className="text-sm">Export</p>
            </Button>
          </Popover>
          <Popover title="Select runbooks to compare">
            <Button disabled={true} type="primary" className="btn-primary">
              <p className="text-sm">Compare</p>
            </Button>
          </Popover>
        </div>
      </Row>
      <Table
        className="mt-2 runbook-table"
        dataSource={filtered}
        columns={columns}
        scroll={{ x: "max-content" }}
        rowKey={"id"}
        pagination={{ defaultPageSize: 20 }}
      />
    </div>
  );
};

export default RunbookStore;
