import React from "react";

const Substep = ({ substep, index, isChild }) => {
  return (
    <div>
      {substep?.subSteps?.length > 0 ? (
        <ul
          style={{
            marginLeft: isChild?"30px":"0px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <li style={{}}>
           {index}. {substep.description ? `${substep?.description} : ` : ""}{" "}
            {substep?.step}
          </li>
          {substep?.subSteps.map((substep, index) => (
            <Substep substep={substep} index={index+1} isChild={true} />
          ))}
        </ul>
      ) : (
        <ul
        style={{
          marginLeft: isChild?"30px":"0px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <li style={{}}>
        {index}.  {substep.description ? `${substep?.description} : ` : ""}{" "}
          {substep?.step}
        </li>
        </ul>
      )}
    </div>
  );
};

export default Substep;
