import { Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./nistcsf.css";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import V1_1NIST from "./v1.1";
import { GetApi } from "../../service/http";
const Nistcsf = ({ data }) => {
  const [vendorData , setVendorData] = useState();
  const fetchData =async()=>{

    const result = await GetApi("get", `runbookService/api/v1/vendors?installed=${true}`);
    setVendorData(result)
  }
  const items = [
    {
      key: "1",
      label: (<p>NIST CSF v1.1</p>),
      children: <V1_1NIST data={data} vendorData={vendorData} />,
    },
    {
      key: "2",
      label: (<p>NIST CSF v2.0</p>),
      children: "Content of Tab Pane 2",
      disabled: true,
    },
    // {
    //   key: '3',
    //   label: 'Cybersecurity Controls',
    //   children: 'Content of Tab Pane 3',
    //   disabled: true
    // },
    // {
    //   key: '4',
    //   label: 'CIS',
    //   children: 'Content of Tab Pane 3',
    //   disabled: true
    // },
  ];
  useEffect(()=>{
    fetchData();
  },[])
  return (
    <Row className="nist-container">
      <Row className="bg-priamry font-black nist-header">
        <BreadcrumbComponent />
      </Row>
      <Tabs defaultActiveKey="1" items={items} style={{ marginLeft: "25px" }} />
    </Row>
  );
};

export default Nistcsf;
