import React, { useState } from "react";
import {  Button, notification, Modal } from "antd";

import ImportModalContent from "./importModalContent";
import {  MethodApi } from "../../service/http";
import ModalContent from "./modalContent";
const GenreicModal = ({ buttonText, modalTitle, categoryData , isAutomation ,importFinishHandler, onAddAutomation}) => {
  const [openModals, setOpenModals] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const showModal = () => {
    setOpenModals(true);
  };
  const handleOk = () => {
    setOpenModals(false);
  };

  const handleCancel = () => {
    setOpenModals(false);
  };
  const finishHandler = async (value) => {
    const formData = new FormData();
    formData.append("vendor", value.vendor);
    formData.append("category", value.category);
    formData.append("version", value.version);
    formData.append("runbook", value.file.file.originFileObj);
    await MethodApi({
      method: "post",
      pathname: "runbookService/api/v1/store",
      body: formData,
      onSuccess: () => {
        importFinishHandler()
        api["success"]({
          message:  "Imported Successfully",
          duration: 2,
        });
      },
      onError: (error) => {
       
        api['error']({
          message: error.message,  
          duration: 2,        
        });
      },
    });

    setOpenModals(false);
  };
  const automationFinishHanlder = (opt)=>{
    onAddAutomation(opt)
    setOpenModals(false);
  }
  return (
    <div className="modal-class">
      {contextHolder}
      <Button
        type="primary"
        className="btn-primary"
        onClick={() => showModal()}
      >
        {buttonText}
      </Button>
      <Modal
        title={modalTitle}
        open={openModals}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
       {!isAutomation &&  <ImportModalContent
          categoryData={categoryData}
          finishHandler={(value) => finishHandler(value)}
        />}
        {isAutomation && <ModalContent automationFinishHanlder={automationFinishHanlder}/>}
      </Modal>
    </div>
  );
};

export default GenreicModal;
