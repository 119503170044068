import React from "react";
import Home from "../../component/home/home.jsx";


import "./index.css";

const Index = () => {
  
  return (
    <Home
    />
  );
};

export default Index;
