import { Card, Col, Row } from "antd";
import React from "react";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./csf_category.css";
const CsfCategory = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const subcategoryHandler = (subcategoryId ,subcategoryname)=>{
    navigate(`/nist_csf/${params.categoryId}/${subcategoryId}?category=${category}&subcategoryname=${subcategoryname}`)
  }
  return (
    <div className="cyber-category-container">
    <Row className="bg-priamry font-black cyber-category-header">
      <BreadcrumbComponent category={category}  />
    </Row>
    <Card
      title={
        <Row className="font-secondary">
          <Col
            
            align="left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p
              className={`summary-category category-options-${category}`}
            >
              {category}
            </p>
          </Col>
        </Row>
      }
      className="cyber-category-card bg-secondary"
    >
      {data?.entries?.map((entry, index) => (
        <Row key={index} className="font-default p-1">
          <Col  align="left">
            <p
              className="text-sm  lg:text-xs"
              style={{
                color: "rgb(1 100 127)" ,
                cursor:  "pointer" ,
              }}
              onClick={() => subcategoryHandler(entry?.subcategoryid , entry?.subcategoryname)}
            >
              {entry.subcategoryid} :- {entry?.subcategoryname}
            </p>
          </Col>
        </Row>
      ))}
    </Card>
  </div>
  );
};

export default CsfCategory;
