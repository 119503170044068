export const taskMockData = {
  todo: [{
    taskid: "5",
    status: "todo",
    taskname: "Set Up Access Control and Policies - Part 5",
    assignee: "Ken Williams",
    reviewer: "Olivia Perry",
    creator: "Olivia Perry",
    role: "Operator",
    description: "Implement S3 event notifications to trigger actions on specific events such as object creation or deletion.",
    createdAt: "20240702",
    updatedAt: "20240702",
    hrcLevel: "1",
    "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
    ownerSteps: [
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "4.3",
        proceduralStepDescription: "Configure Access Logging for S3 Buckets",
        stepIndex: 1
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "4.3",
        proceduralStepDescription: "Set Up Change Tracking for Bucket Policies, ACLs, and Object Permissions",
        stepIndex: 2
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "2.6",
        proceduralStepDescription: "Verify Changes",
        stepIndex: 4
      },
    ],
  }],
  inProgress: [
    {
      taskid: "3",
      status: "inProgress",
      taskname: "Set Up Access Control and Policies - Part 3",
      hrcLevel: "1",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      assignee: "Ken Williams",
      reviewer: "Olivia Perry",
      creator: "Olivia Perry",
      role: "Operator",
      description: "Create and attach IAM policies to control access for users and applications.",
      createdAt: "20240702",
      updatedAt: "20240702",
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.3",
          proceduralStepDescription: "Define Bucket Policy Requirements",
          stepIndex: 1
        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.3",
          proceduralStepDescription: "Create Bucket Policy",
          stepIndex: 2
        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "2.1",
          proceduralStepDescription: "Implement IAM Policies",
          stepIndex: 3
        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "2.1",
          proceduralStepDescription: "Use IAM Roles and Groups",
          stepIndex: 4
        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "3.5",
          proceduralStepDescription: "Create IAM Roles for Cross-Account Access",
          stepIndex: 3
        }
      ],
    },
    {
      taskid: "4",
      status: "inProgress",
      taskname: "Set Up Access Control and Policies - Part 4",
      hrcLevel: "2",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      assignee: "Ken Williams",
      reviewer: "Olivia Perry",
      creator: "Olivia Perry",
      role: "Operator",
      description: "Configure Cross-Origin Resource Sharing (CORS) policies to enable web application interactions.",
      createdAt: "20240702",
      updatedAt: "20240702",
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "3.3",
          proceduralStepDescription: "Implement Boundary Protection",
          stepIndex: 3,
          watchTeamValidation: true
        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "3.3",
          proceduralStepDescription: "Control Configuration Changes",
          stepIndex: 4
        },
        { 
          runbookname: "AWS CloudFront HRO Runbook",
          processNumber: "3.2",
          proceduralStepDescription: "Cache Behavior", 
          stepIndex: 1, 
          missing: true
        },
        {
          runbookid: 22,
          runbookname: "AWS EC2 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Configure CORS Headers",
          stepIndex: 3
        },
        {
          runbookid: 22,
          runbookname: "AWS EC2 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Enforce Access Permissions (AC-4)",
          stepIndex: 4
        },

      ],
    },
  ],
  inReview: [
    {
      taskid: "2",
      status: "inReview",
      taskname: "Set Up Access Control and Policies - Part 2",
      hrcLevel: "3",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      assignee: "Ken Williams",
      reviewer: "Olivia Perry",
      creator: "Olivia Perry",
      role: "Operator",
      description: "Disable Access Control Lists (ACLs) for S3 objects.",
      createdAt: "20240702",
      updatedAt: "20240702",
      ownerSteps: [
        {
          updatedAt: "20240702",
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Modify Bucket Policy",
          stepIndex: 2
        },
        {
          updatedAt: "20240702",
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Disable Object ACLs",
          stepIndex: 3
        },
        {
          updatedAt: "20240702",
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Verify Changes",
          stepIndex: 4,
          watchTeamValidation: true
        },
      ],
    },
    
  ],
  done: [
    {
      taskid: "1",
      status: "done",
      taskname: "Set Up Access Control and Policies - Part 1",
      hrcLevel: "3",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      assignee: "Ken Williams",
      reviewer: "Olivia Perry",
      creator: "Olivia Perry",
      role: "Operator",
      description: "Define and apply bucket policies to manage access at the bucket level.",
      createdAt: "20240702",
      updatedAt: "20240702",
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.2",
          proceduralStepDescription: "MFA Implementation and Management",
          stepIndex: 1,
          updatedAt: "20240702",
          reviwerupdatedAt:  "20240702",
        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.2",
          proceduralStepDescription: "Access Control and Enforcement",
          stepIndex: 2,
          updatedAt: "20240702",
          reviwerupdatedAt:  "20240702",
        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "3.1",
          proceduralStepDescription: "Attach Managed Policies to IAM Entities",
          stepIndex: 4,
          watchTeamValidation: true,
          updatedAt: "20240702",
          reviwerupdatedAt:  "20240702",
        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "3.1",
          proceduralStepDescription: "Monitor and Review Permissions",
          stepIndex: 5,
          updatedAt: "20240702",
          reviwerupdatedAt:  "20240702",
        }
      ]
    },
  ],
  taskTemplates: [
    {
      createdAt: "20240702",
      templateId: "1",
      hrcLevel: "1",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      templateName: "Set Up Access Control and Policies - Part 1",
      description: "Define and apply bucket policies to manage access at the bucket level.",
      runbooks: [
        {
          vendorCode: "AWS",
          id: 23,
          name: "AWS S3 HRO Runbook",
        }, {
          name: "AWS IAM HRO Runbook",
          id: 2,
          vendorCode: "AWS"
        }
      ],
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.2",
          proceduralStepDescription: "MFA Implementation and Management",
          stepIndex: 1,
        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.2",
          proceduralStepDescription: "Access Control and Enforcement",
          stepIndex: 2

        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "3.1",
          proceduralStepDescription: "Attach Managed Policies to IAM Entities",
          stepIndex: 4,
          watchTeamValidation: true
        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "3.1",
          proceduralStepDescription: "Monitor and Review Permissions",
          stepIndex: 5

        }
      ]
    },
    {
      createdAt: "20240702",
      templateId: "2",
      hrcLevel: "2",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      templateName: "Set Up Access Control and Policies - Part 2",
      description: "Disable Access Control Lists (ACLs) for S3 objects.",
      runbooks: [
        {
          vendorCode: "AWS",
          id: 23,
          name: "AWS S3 HRO Runbook",
        }
      ],
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Modify Bucket Policy",
          stepIndex: 2

        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Disable Object ACLs",
          stepIndex: 3


        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Verify Changes",
          stepIndex: 4,
          watchTeamValidation: true

        },
      ]
    },
    {
      createdAt: "20240702",
      templateId: "3",
      hrcLevel: "1",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      templateName: "Set Up Access Control and Policies - Part 3",
      description: "Create and attach IAM policies to control access for users and applications.",
      runbooks: [
        {
          vendorCode: "AWS",
          id: 23,
          name: "AWS S3 HRO Runbook",
        }, {
          name: "AWS IAM HRO Runbook",
          id: 2,
          vendorCode: "AWS"
        }
      ],
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.3",
          proceduralStepDescription: "Define Bucket Policy Requirements",
          stepIndex: 1

        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.3",
          proceduralStepDescription: "Create Bucket Policy",
          stepIndex: 2


        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "2.1",
          proceduralStepDescription: "Implement IAM Policies",
          stepIndex: 3

        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "2.1",
          proceduralStepDescription: "Use IAM Roles and Groups",
          stepIndex: 4

        },
        {
          runbookid: 2,
          runbookname: "AWS IAM HRO Runbook",
          processNumber: "3.5",
          proceduralStepDescription: "Create IAM Roles for Cross-Account Access",
          stepIndex: 3
        }
      ]
    },
    {
      createdAt: "20240702",
      templateId: "4",
      hrcLevel: "2",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      templateName: "Set Up Access Control and Policies - Part 4",
      description: "Configure Cross-Origin Resource Sharing (CORS) policies to enable web application interactions.",
      unknownSteps:[
        { 
          runbookname: "AWS CloudFront HRO Runbook",
          processNumber: "3.2",
          proceduralStepDescription: "Cache Behavior", 
          stepIndex: 1
        },
      ],

      runbooks: [
        {
          vendorCode: "AWS",
          id: 23,
          name: "AWS S3 HRO Runbook",
        }, {
          name: "AWS EC2 HRO Runbook",
          id: 22,
          vendorCode: "AWS"
        }
      ],
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "3.3",
          proceduralStepDescription: "Implement Boundary Protection",
          stepIndex: 3,
          watchTeamValidation: true

        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "3.3",
          proceduralStepDescription: "Control Configuration Changes",
          stepIndex: 4


        },
        {
          runbookid: 22,
          runbookname: "AWS EC2 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Configure CORS Headers",
          stepIndex: 3

        },
        {
          runbookid: 22,
          runbookname: "AWS EC2 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Enforce Access Permissions (AC-4)",
          stepIndex: 4

        },
      ]
    },
    {
      createdAt: "20240702",
      templateId: "5",
      hrcLevel: "1",
      "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
      templateName: "Set Up Access Control and Policies - Part 5",
      description: "Implement S3 event notifications to trigger actions on specific events such as object creation or deletion.",
      runbooks: [
        {
          vendorCode: "AWS",
          id: 23,
          name: "AWS S3 HRO Runbook",
        }
      ],
      ownerSteps: [
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "4.3",
          proceduralStepDescription: "Configure Access Logging for S3 Buckets",
          stepIndex: 1
        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "4.3",
          proceduralStepDescription: "Set Up Change Tracking for Bucket Policies, ACLs, and Object Permissions",
          stepIndex: 2
        },
        {
          runbookid: 23,
          runbookname: "AWS S3 HRO Runbook",
          processNumber: "2.6",
          proceduralStepDescription: "Verify Changes",
          stepIndex: 4

        },
      ]
    },
  ],
  dummyTemplates: [{
    createdAt: "20240702",
    templateId: "1001",
    hrcLevel: "3",
    "awsAccount":"https://123456789012.signin.aws.amazon.com/console",
      "awsS3URI":"https://horizonops-data.s3.amazonaws.com/config/app-config.json",
    templateName: "Template: AWS S3 1.1 - Create S3 Bucket",
    description: "To activate versioning on S3 buckets to safeguard against accidental deletions and overwrites, thereby enhancing data protection and recovery capabilities. This process ensures that previous versions of objects are retained and recoverable, providing an additional layer of data integrity and security.",
    runbooks: [
      {
        vendorCode: "AWS",
        id: 23,
        name: "AWS S3 HRO Runbook",
      }
    ],
    ownerSteps: [
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "1.1",
        proceduralStepDescription: "Create S3 Bucket",
        stepIndex: 1,
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "2.5",
        proceduralStepDescription: "Apply Block Public Access Settings at Account Level",
        stepIndex: 2
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "2.5",
        proceduralStepDescription: "Apply Block Public Access Settings at Bucket Level",
        stepIndex: 3
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "2.5",
        proceduralStepDescription: "Verify Account and Bucket Level Settings",
        stepIndex: 4
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "3.1",
        proceduralStepDescription: "Configure Data-at-Rest Encryption",
        stepIndex: 1
      },
      {
        runbookid: 23,
        runbookname: "AWS S3 HRO Runbook",
        processNumber: "3.1",
        proceduralStepDescription: "Implement Data-in-Transit Encryption",
        stepIndex: 2
      }
      
    ]}],
  updateTaskTemplates(templateList) {
    this.taskTemplates = templateList
  },
  updateTaskNotes(taskId, _date, notes) {
    const allTask = [...this.inProgress, ...this.todo, ...this.done, ...this.inReview]
    const foundTask = allTask.find(task => task.taskid === taskId);
    if (foundTask) {
      foundTask.notes = notes
    }
  },

  updateTaskData(taskId, updatedAt, taskIndex, isDone) {
    const allTask = [...this.inProgress, ...this.todo, ...this.done, ...this.inReview]
    const foundTask = allTask.find(task => task.taskid === taskId);
    if (isDone && foundTask && foundTask.ownerSteps[taskIndex]) {
      foundTask.ownerSteps[taskIndex].updatedAt = updatedAt;
    } else {
      foundTask.ownerSteps[taskIndex].updatedAt = null;
    }
    if (isDone) {
      const allUpdated = foundTask.ownerSteps.filter(p=>!p.missing).every(process => process.updatedAt);
      // check if any checkbox with review
      const hasWatchTeamValidation = foundTask.ownerSteps
      .some(process => process.watchTeamValidation);
      if (allUpdated) {
        foundTask.nextStage = hasWatchTeamValidation ? "inReview": "done" ;
      } else {
        foundTask.nextStage = ""
      }
    } else {
      foundTask.nextStage = ""
    }
  },
  updateReviewTaskData(taskId, updatedAt, taskIndex, isDone) {
    const allTask = [...this.inProgress, ...this.todo, ...this.done, ...this.inReview]
    const foundTask = allTask.find(task => task.taskid === taskId);
    if (isDone && foundTask && foundTask.ownerSteps[taskIndex]) {
      foundTask.ownerSteps[taskIndex].reviwerupdatedAt = updatedAt;
    } else {
      foundTask.ownerSteps[taskIndex].reviwerupdatedAt = null;
    }
    if (isDone) {
      const allUpdated = foundTask.ownerSteps
        .filter(process => process.watchTeamValidation)
        .filter(p=>!p.missing)
        .every(process => process.reviwerupdatedAt);

      if (allUpdated) {
        foundTask.nextStage = "done";
      } else {
        foundTask.nextStage = ""
      }
    } else {
      foundTask.nextStage = ""
    }
  },
  updateMoveTaskData(taskId, updatedAt, status) {
    const allTask = [...this.inProgress, ...this.todo, ...this.done, ...this.inReview]
    const foundTask = allTask.find(task => task.taskid === taskId);
    if (foundTask) {
      foundTask.updatedAt = updatedAt;
      foundTask.nextStage = ""
      if (status === "done") {
        foundTask.ownerSteps.forEach((process, _) => {
          process.reviwerupdatedAt = updatedAt
          process.updatedAt = updatedAt
        })
      } else if (status === "inReview") {
        foundTask.ownerSteps.forEach((process, _) => {
          process.reviwerupdatedAt = null
          process.updatedAt = updatedAt
        })
      } else {
        foundTask.ownerSteps.forEach((process, _) => {
          process.reviwerupdatedAt = null
          process.updatedAt = null
        })
      }
    } else {
    }
  }


};