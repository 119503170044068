import React from 'react';
import { Button, Modal, Typography, Divider, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
const { Title, Text, Paragraph, Link } = Typography;

const ViewTemplateModal = ({ template, visible, onClose }) => {

  const runbookDetailHandler = (runbookid, processnumber, stidx) => {
    // navigate(
    //   `/installrunbook/AWS/${runbookid}?isInstalled=true?isInstalled=${true}&processnumber=${processnumber}&step=${stidx}`
    // );
    navigate(
      `/task/template/${runbookid}?isInstalled=true?isInstalled=${true}&processnumber=${processnumber}&step=${stidx}`
    );
  };

  const getHRCLevel = (level)=>{
    const descriptions = {
      "0": 'No HRC',
      "1": 'HRC Low',
      "2": 'HRC High',
      "3": 'HRC Very High'
    };
  
    return descriptions[level] || 'No HRC';
  }
  const navigate = useNavigate();
  if (!template) return null;
  
  return (
    <Modal
      title="Template Details"
      visible={visible}
      onCancel={onClose}
      footer={<Button type="primary" onClick={onClose}>Close</Button>}
    >
      <div>
        {/* <Title level={4}>Template Details</Title> */}
        <Text strong>Template Name:</Text>
        <Paragraph>{template.templateName}</Paragraph>
        <Text strong>Template Description:</Text>
        <Paragraph>{template.description}</Paragraph>
        <Text strong>HRC Level:</Text>
        <Paragraph>{getHRCLevel(template.hrcLevel)}</Paragraph>
        <Text strong>Account Information:</Text>
        <Paragraph>
          {template.awsAccount ? (
            <Link href={template.awsAccount} style={{ color: "rgb(1 100 127)" }} target="_blank">
              {template.awsAccount}
            </Link>
          ) : (
            "Not Set"
          )}
        </Paragraph>
        <Text strong>S3 Bucket Object Resource URI:</Text>
        <Paragraph>
          {template.awsS3URI ? (
            <Link href={template.awsS3URI} style={{ color: "rgb(1 100 127)" }} target="_blank">
              {template.awsS3URI}
            </Link>
          ) : (
            "Not Set"
          )}
        </Paragraph>
        <Divider style={{borderBottom: "1px solid rgba(0, 0, 0, 0.4)"}} />

        {template.ownerSteps && template.ownerSteps.length > 0 && (
          <>
            <Title level={5}>Operator Steps</Title>
            {template.ownerSteps.map((step, index) => (
              <Paragraph key={index}>
              <Text strong>Runbook:</Text> {step.runbookname} <Text  onClick={()=>{
            runbookDetailHandler(step.runbookid, step.processNumber, step.stepIndex)
          }} strong style={{
            cursor: "pointer",
            color: "rgb(1 100 127)"}}>{step.processNumber}</Text>
                <br />
                <Text strong>Step Description:</Text> {step.proceduralStepDescription}
              </Paragraph>
            ))}
            <br></br>
          </>
        )}
        {template.unknownSteps && template.unknownSteps.length > 0 && (
          <>
           <Title level={5} style={{ color: 'red' }}>Unknown Steps <Tooltip title="Runbook is missing" trigger={['hover', 'click']}>
      <InfoCircleOutlined style={{ fontSize: '14px', color: '#08c' }} />
      </Tooltip></Title>
            {template.unknownSteps.map((step, index) => (
              <Paragraph key={index}>
                <Text strong>Runbook:</Text> {step.runbookname}
                <br />
                <Text strong>Step Description:</Text> {step.proceduralStepDescription}
                <Divider></Divider>
              </Paragraph>
            ))}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ViewTemplateModal;
