import { Card, Checkbox, Col, Divider, Popover, Row, Button } from "antd";
import React, { useState, useEffect } from "react";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import "./taskProcess.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { taskMockData } from "../../mockdata/mockJson";
import ThreeDotsMenu from "./threedotmenu";
import moment from "moment";
import { getRoleByName } from "../../utils/index";
import NotesSection from "./notes";
const getHRCLevel = (level)=>{
  const descriptions = {
    "0": 'No HRC',
    "1": 'HRC Low',
    "2": 'HRC High',
    "3": 'HRC Very High'
  };

  return descriptions[level] || 'No HRC';
}
const TaskProcess = ({ StatusChanged, setUserName }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taskname = queryParams.get("taskname");
  const taskstatus = queryParams.get("taskstatus");
  const [status, setStatus] = useState(taskstatus);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    const task = findTaskById(params.taskid, Tasks);
    if (task) {
      if (taskstatus === "inReview") {
        setUserName(task.reviewer + "_r");
      } else {
        setUserName(task.assignee + "_o" || "Mark Wood_o");
      }
    }

    return () => {
      setUserName("");
    };
  }, [taskstatus, params.taskid]);

  const findTaskArray = (status) => {
    switch (status) {
      case "todo":
        return taskMockData.todo || [];
      case "inProgress":
        return taskMockData.inProgress || [];
      case "inReview":
        return taskMockData.inReview || [];
      case "done":
        return taskMockData.done || [];
      default:
        return [];
    }
  };

  const runbookDetailHandler = (runbookid, processnumber, step) => {
    navigate(
      `/task/${params?.taskid}/${runbookid}?isInstalled=${true}&processnumber=${processnumber}&taskname=${taskname}&taskstatus=${taskstatus}&step=${step}`
    );
  };

  const filterBasedOnStatus = (Tasks, status) => {
    const filteredTasks = [];
    for (const task of Tasks) {
      if (task?.status === status) {
        filteredTasks.push(task);
      }
    }
    return filteredTasks;
  };

  const StatusChangeHandler = (status) => {
    for (const task of Tasks) {
      if (task?.taskid === params.taskid) {
        task.status = status;
        setStatus(status);
        break;
      }
    }
    const NewTasks = filterBasedOnStatus(Tasks, status);
    taskMockData.updateMoveTaskData(task.taskid, moment(), status);
    setReloadKey((prevKey) => prevKey + 1);
    StatusChanged(NewTasks, taskstatus, task.taskid, task.status);
    return null;
  };

  const findTaskById = (taskId, Tasks) => {
    for (const task of Tasks) {
      if (task.taskid === taskId) {
        return task;
      }
    }
    return null;
  };

  const handleCheckboxChange = (taskId, updatedAt, taskIndex, stateVal) => {
    taskMockData.updateTaskData(taskId, updatedAt, taskIndex, stateVal);
    setReloadKey((prevKey) => prevKey + 1);
  };

  const handleCheckboxReviwerChange = (taskId, updatedAt, taskIndex, stateVal) => {
    taskMockData.updateReviewTaskData(taskId, updatedAt, taskIndex, stateVal);
    setReloadKey((prevKey) => prevKey + 1);
  };

  var Tasks = findTaskArray(taskstatus);
  const task = findTaskById(params.taskid, Tasks);

  function truncateString(str, maxWords) {
    const words = str?.trim()?.split(/\s+/);
    if (words?.length > maxWords) {
      return words?.slice(0, maxWords)?.join(" ") + "...";
    }
    return str;
  }

  return (
    <div className="tasks-process-container" key={reloadKey}>
      <Row className="bg-priamry font-black tasks-process-header">
        <BreadcrumbComponent taskname={truncateString(taskname, 7)} taskstatus={taskstatus} />
      </Row>
      <Card
        title={
          <Row
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "5px",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                width: "60%",
                textAlign: "left",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className="text-sm lg:text-xs"
            >
              {task?.taskname}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "40%",
                justifyContent: "flex-end",
              }}
            >
              <span>Owner</span>
              <Popover title={task?.creator}>
                <img
                  src={`/images/${getRoleByName(task?.creator)}.png`}
                  alt="Owner"
                  width="25"
                  height="25"
                />
              </Popover>
              <ThreeDotsMenu
                selectedStatus={status}
                StatusChangeHandler={(status) => StatusChangeHandler(status)}
                isReviewer={task?.reviewer === "" ? false : true}
              />
            </div>
          </Row>
        }
        className="border-primary task-process-card"
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div>
            <Row
              style={{
                width: "100%",
              }}
            >
              <p className="text-sm font-bold lg:text-xs">Purpose</p>
            </Row>
            <Divider style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.4)" }} />
            <Row>
              <p className="text-sm" style={{ textAlign: "left" }}>
                {task?.description}
              </p>
            </Row>
            <div
              style={{
                width: "100%",
                marginTop: "25px",
              }}
            >
              <p className="text-sm lg:text-xs text-left">
                <span style={{ fontWeight: "bold" }}>Created At -</span>{" "}
                {moment(task?.createdAt, "YYYYMMDD").format("MMMM DD, YYYY")}
              </p>
              <br />
              {task?.hrcLevel !=-1 && 
              <>
              <p className="text-sm lg:text-xs text-left">
                <span style={{ fontWeight: "bold" }}>HRC Level -</span>{" "}
                {getHRCLevel(task?.hrcLevel)}
              </p>
              <br/>
              </>
}

            </div>
            {task?.awsAccount && (
        <>
          <p className="text-sm lg:text-xs text-left">
            <span style={{ fontWeight: "bold" }}>Account Information -</span>{" "}
            <a href={task.awsAccount} rel="noreferrer" target="_blank" style={{ color: "rgb(1 100 127)" }}>
              {task.awsAccount}
            </a>
          </p>
          <br />
        </>
      )}
      {task?.awsS3URI && (
        <>
          <p className="text-sm lg:text-xs text-left">
            <span style={{ fontWeight: "bold" }}>S3 Bucket Object Resource URI -</span>{" "}
            <a href={task.awsS3URI} rel="noreferrer" target="_blank" style={{ color: "rgb(1 100 127)" }}>
              {task.awsS3URI}
            </a>
          </p>
          <br />
        </>
      )}
            <Divider style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.4)" }} />
          </div>
          <div>
            <Row style={{ width: "100%" }}>
              <Col span={8} align="left">
                <p className="text-sm font-bold lg:text-xs">Procedural Steps</p>
              </Col>
              <Col span={8} align="left">
                <p className="text-sm font-bold lg:text-xs">Runbook</p>
              </Col>
              {task?.reviewer && (
                <Col span={4} align="right" className="task-card-body-proccess-header">
                  <p className="text-sm font-bold lg:text-xs">Reviewer</p>
                  <Popover title={task?.reviewer}>
                    <img
                      src={`/images/${getRoleByName(task?.reviewer)}.png`}
                      alt="Operator"
                      width="25"
                      height="25"
                    />
                  </Popover>
                </Col>
              )}
              <Col span={task?.reviewer ? 4 : 8} align="right" className="task-card-body-proccess-header">
                <p className="text-sm font-bold lg:text-xs">Operator</p>
                <Popover title={task?.assignee}>
                  <img
                    src={`/images/${getRoleByName(task?.assignee)}.png`}
                    alt="Operator"
                    width="25"
                    height="25"
                  />
                </Popover>
              </Col>
            </Row>
            <Divider style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.4)" }} />
            {task?.ownerSteps?.map((process, index) => (
              <Row key={index} style={{ width: "100%", marginTop: "10px" }}>
                <Col span={8} align="left">
                  <p
                    className="text-sm lg:text-xs"
                    style={{
                      cursor: process.missing ? "not-allowed" : "pointer",
                      color: process.missing ? "red" : "rgb(1 100 127)",
                    }}
                    onClick={() =>
                      !process.missing &&
                      runbookDetailHandler(process?.runbookid, process.processNumber, process.stepIndex)
                    }
                  >
                    {process?.proceduralStepDescription || process?.processName}
                  </p>
                </Col>
                <Col span={8} align="left">
                  <p className="text-sm lg:text-xs" style={{ color: process.missing ? "red" : "rgb(1 1 1)", }}>
                    {process?.runbookname}
                  </p>
                </Col>
                {task?.reviewer !== "" && (
                  <Col span={4} style={{ textAlign: "right" }}>
                    {process.watchTeamValidation ? (
                      <>
                        {process.reviwerupdatedAt && (
                          <span style={{ marginRight: "5px", fontSize: "12px" }}>
                            {moment(process.reviwerupdatedAt).format("MMMM DD, YYYY")}
                          </span>
                        )}
                        <Checkbox
                          defaultChecked={process.reviwerupdatedAt ? true : false}
                          disabled={task?.status !== "inReview" || process.missing}
                          onChange={(e) =>
                            handleCheckboxReviwerChange(
                              task?.taskid,
                              moment(),
                              index,
                              e.target.checked
                            )
                          }
                        ></Checkbox>
                      </>
                    ) : null}
                  </Col>
                )}
                <Col span={task?.reviewer ? 4 : 8} style={{ textAlign: "right" }}>
                  {process.updatedAt && (
                    <span style={{ marginRight: "5px", fontSize: "12px" }}>
                      {moment(process.updatedAt).format("MMMM DD, YYYY")}
                    </span>
                  )}
                  <Checkbox
                    defaultChecked={process.updatedAt ? true : false}
                    disabled={task?.status !== "inProgress" || process.missing}
                    onChange={(e) =>
                      handleCheckboxChange(
                        task?.taskid,
                        moment(),
                        index,
                        e.target.checked
                      )
                    }
                  ></Checkbox>
                </Col>
              </Row>
            ))}
            <Row style={{ width: "100%", marginTop: "5px" }}>
              <Col offset={14} span={6} style={{ textAlign: "right" }}>
                {task?.nextStage === "done" && task?.status !== "done" && (
                  <Button onClick={() => StatusChangeHandler("done")} className="btn-primary move-it-btn">
                    Move to <b> Done</b>
                  </Button>
                )}
              </Col>
              <Col offset={21} span={3} style={{ textAlign: "right" }}>
                {task?.nextStage === "inReview" && task?.status !== "inReview" && (
                  <Button onClick={() => StatusChangeHandler("inReview")} className="btn-primary move-it-btn">
                    Move to <b> In Review</b>
                  </Button>
                )}
              </Col>
            </Row>
            
          </div>
          </Row>
          <div style={{ marginTop: "5px" }}>
            {task && <NotesSection task={task} setReloadKey={setReloadKey} taskMockData={taskMockData} tskStatus={status} />}
          </div>
        
      </Card>
    </div>
  );
};

export default TaskProcess;
