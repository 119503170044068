import React from 'react'
import TaskProcess from "../../component/taskProcess/taskProcess"
const Index = ({StatusChanged,setUserName}) => {
 
  return (
    <div>
      <TaskProcess setUserName={setUserName} StatusChanged={(tasks, lasttaskstatus , taskid , newstatus )=>StatusChanged(tasks ,lasttaskstatus , taskid , newstatus)}/>
    </div>
  )
}

export default Index
