import { Card, Divider, Input, Row, Popover } from "antd";
import React, { useState } from "react";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import "./vendor.css";
import { SearchOutlined, } from "@ant-design/icons";
import { AiFillAppstore } from "react-icons/ai"
import { IoCartOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdOutlineLocalMall } from "react-icons/md";


const Vendor = ({ pathname, isInstalled, data, categoryData, getApiHandler }) => {
  const [searchText, setSearchText] = useState("");
  var totalRunbook = 0;
  var totalcves = 0;
  for (var i = 0; i < data?.vendors?.length; i++) {
    totalRunbook += data?.vendors[i].runbooks;
  }
  for (i = 0; i < data?.vendors?.length; i++) {
    totalcves += data?.vendors[i].cve;
  }

  const navigate = useNavigate();
  const vendorHandler = (vendorCode, vendorRunbook) => {
    navigate(`/${pathname}/${vendorCode}`);

  };

  const sortVendorArr = data?.vendors?.sort((a, b) => a.name.localeCompare(b.name));
  const handleSearch = (value) => {
    setSearchText(value.toLowerCase().trim());
  };
  const filtered = sortVendorArr?.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <div className="vendor-container">
      <Row className="bg-priamry font-black breadcrum vendor-header">
        <BreadcrumbComponent />
        <>
          {pathname !== "runbookstore" ? (
            <Popover
              content="Runbook Store"
              trigger="hover"
              placement="bottom"
            >
              <IoCartOutline
                cursor={'pointer'}
                size={34}
                onClick={() => navigate("/runbookstore")}
              />
            </Popover>
          ) : (
            <Popover
              content="Installed Runbooks"
              trigger="hover"
              placement="bottom"
            >
              {/* <svg
            onClick={() => navigate("/installrunbook")}
            cursor={'pointer'}
            className="font-default"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 26"
          >
            <path d="M8.44553 23.9779V20.1519C8.44553 19.1752 9.24309 18.3834 10.2269 18.3834H13.8233C14.2958 18.3834 14.7489 18.5697 15.083 18.9014C15.417 19.233 15.6047 19.6828 15.6047 20.1519V23.9779C15.6017 24.384 15.7621 24.7744 16.0503 25.0626C16.3385 25.3508 16.7306 25.5128 17.1396 25.5128H19.5933C20.7392 25.5158 21.8392 25.0659 22.6506 24.2626C23.4619 23.4592 23.9179 22.3683 23.9179 21.2307V10.3308C23.9179 9.4119 23.5076 8.54024 22.7975 7.95067L14.4507 1.33289C12.9988 0.172562 10.9185 0.210026 9.50989 1.42186L1.35356 7.95067C0.609958 8.52286 0.165518 9.39711 0.143555 10.3308V21.2196C0.143555 23.5907 2.07977 25.5128 4.4682 25.5128H6.8658C7.71535 25.5128 8.40577 24.8324 8.41192 23.9891L8.44553 23.9779Z" />
          </svg>  */}
              <MdOutlineLocalMall
                cursor={'pointer'}
                size={34}
                onClick={() => navigate("/installrunbook")}
              />
            </Popover>
          )}
        </>
      </Row>

      <Row className="vendorHeader-card-container">
        <Card className="vendorHeaderCard">
          <p className="text-3xl">{totalRunbook} </p>
          <p className="text-lh">Runbooks</p>
        </Card>
        {/* <Card className="vendorHeaderCard">
          <p className="text-3xl">
            {totalcves || 1}
          </p>
          <p className="text-lh">CVEs</p>
        </Card> */}
      </Row>
      <Divider className="border-primary" />
      {/* {!isInstalled &&  <Row>
        <GenreicModal
            buttonText={"Import"}
            modalTitle={"Import"}
            categoryData={categoryData}
            importFinishHandler={importFinishHandler}
          />
          </Row>} */}
      <Row className="p-2 mt-4 mb-4 install-search-container">
        <Input
          className="search-install-runbooks"
          addonBefore={<SearchOutlined />}
          placeholder="Search By Name"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {/* <Select style={{minWidth:"150px"}} placeholder="Resiliency">
            <Select.Option value={"low"}>LOW</Select.Option>
            <Select.Option value={"medium"}>MEDIUM</Select.Option>
            <Select.Option value={"high"}>HIGH</Select.Option>
        </Select> */}
      </Row>
      {filtered?.length > 0 && <h2 style={{ textAlign: "left", fontSize: "16px" }}><b>Cloud Vendors</b></h2>}
      <Row className="vendors-card-container">
        {searchText !== "solarwind" && filtered?.map((vendor, index) => (
          <Card
            key={index}
            title={
              <Row style={{ alignItems: "center", justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                {vendor?.name?.startsWith("Amazon") && <img src="/images/AWS.png" alt="solarLOGO" height={50} width={50} />}
                {vendor?.name?.startsWith("Google") && <img src="/images/gcp.svg" alt="solarLOGO" height={50} width={50} />}
                {vendor?.name?.startsWith("Microsoft") && <img src="/images/MICROSOFT.png" alt="solarLOGO" height={50} width={50} />}
                {vendor?.name?.startsWith("Oracle") && <img src="/images/oci.svg" alt="solarLOGO" height={50} width={50} />}
                <p
                  className="text-md"
                  style={{
                    color: "rgb(1 100 127)",
                    cursor: "pointer",
                  }}
                  onClick={() => vendorHandler(vendor.id, vendor.runbooks)}
                >
                  {vendor.name}
                </p>
              </Row>
            }
            className="border-primary vendorsCard"
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="font-sans text-sm">Runbooks</p>
                <p className="font-sans text-sm"> {vendor.runbooks}</p>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="font-sans text-sm">CVEs</p>
                <p className="font-sans text-sm"> {vendor.id==="AWS" ? 1: vendor.cve }</p>
              </div> */}
            </div>
          </Card>
        ))}
      </Row>

      {(searchText.startsWith("so") || searchText == "") && <h2 style={{ textAlign: "left", fontSize: "16px", marginTop: "15px" }}><b>Enterprise Vendors</b></h2>}
      {(searchText.startsWith("so") || searchText == "") && <Row className="vendors-card-container">
        <Card
          title={
            <Row style={{ alignItems: "center", justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
              <img src="/images/solarImage.png" alt="solarLOGO" height={40} width={45} />
              <p
                className="text-md"
                style={{
                  color: "grey",
                  cursor: "not-allowed",
                }}>

                SolarWinds
              </p>
            </Row>

          }
          className="border-primary vendorsCard"
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="font-sans text-sm">Runbooks</p>
              <p className="font-sans text-sm"> {0}</p>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="font-sans text-sm">CVEs</p>
                <p className="font-sans text-sm"> {0}</p>
              </div> */}
          </div>
        </Card>
      </Row>}

    </div>
  );
};

export default Vendor;
