import React, { useEffect, useState } from "react";
import "./navbar.css";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import { getRoleByName, getUserinfoByPath } from "../../utils"
const Navbar = ({ hamburgerClicked, userName }) => {
  const location = useLocation();
  const params = useParams();
  const hamburgerHandler = () => {
    hamburgerClicked();
  };

  const [userInfo, setUserInfo] = useState(getUserinfoByPath(location.pathname, params));
  const [imagePath, setImagePath] = useState("")
  useEffect(() => {
    let userInfo = getUserinfoByPath(location.pathname)
    const qParam = new URLSearchParams(location.search, params);
    const tab = qParam.get("tab");
    if (tab === "2") {
      userInfo = getUserinfoByPath("/nist_csf", params)
    }
    setUserInfo(userInfo)

    setImagePath(`/images/${userInfo?.role}.png`)
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (userName !== "") {
      let userNamePlus = userName.split("_")
      const isR = userNamePlus[1]
      const usr = userNamePlus[0]
      const userInfo = {
        name: usr, role: getRoleByName(usr)
      }
      setImagePath(`/images/${userInfo?.role}.png`)
      if (isR ==="r"){
      userInfo.role = "Reviewer"
      }else{
        userInfo.role = "Operator"
      }
      setUserInfo({...userInfo})
    }
  }, [userName])

  return (
    <div className="navbar-container bg-navbar">
      <img src="/images/VTI_Logo_Turquoise_v2.png" alt="logoImage" className="navbar-logo-image" />
      <div className="navbar-userdata">
        <div className="nav-user-data">
          <p className="font-default font-sans text-sm font-bold">{location.pathname === "/installrunbook/AWS/3" ? "Ken Williams" : userInfo?.name}</p>
          <p className="font-default font-sans text-sm text-right" style={{
            textAlign: "right"
          }} >{location.pathname === "/installrunbook/AWS/3" ? "Operator" : userInfo?.role ==="olivia"? "Operator": userInfo?.role}</p>
        </div>
        {!imagePath ? <svg stroke="currentColor"
          fill="currentColor" stroke-width="0"
          viewBox="0 0 496 512" height="30"
          width="30" xmlns="http://www.w3.org/2000/svg"><path d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"></path></svg>
          : <img src={imagePath} alt={userInfo?.role} width="30" height="30" />}
        <MenuOutlined
          onClick={() => hamburgerHandler()}
          className="text-sm  font-default hamburger-icon"
        />
      </div>
    </div>
  );
};

export default Navbar;
