import React from "react";
import "./policyCategoryDetailDescription.css";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { Row, Spin } from "antd";
import DocViewer, {
  DocViewerRenderers,
} from "@cyntler/react-doc-viewer";
import { useLocation } from "react-router-dom";

const PolicyCategoryDetailDescription = ({ data, uri }) => {
  // const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  // const blobUrl = URL.createObjectURL(blob);
  const docs = [
    {
      fileName: "policy",
      uri: uri,
      fileType: 'pdf'
    },
  ];
  const PolicyLoader = () => <Spin size="large" />;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryname = queryParams.get("categoryname");
  const category = queryParams.get("category");
  const policyname = queryParams.get("policyname");

  return (
    <div className="policy-description-container">
      <Row className="bg-priamry font-black policy-description-header">
        <BreadcrumbComponent
          categoryName={categoryname}
          policyName={policyname}
          category={category}
        />
      </Row>
      <Row className="border-primary" style={{ height: "82vh", width: "100%" , overflow:"hidden"}}>
        <div style={{ width: "100%" }}>
          {/* <iframe 
      frameborder="0"
      width="100%" title="policy" height="500px" 
      src={`https://docs.google.com/gview?url=${uri}&embedded=true`}></iframe> */}
          <DocViewer
            config={{
              // loadingRenderer: {
              //   overrideComponent: PolicyLoader,
              //   showLoadingTimeout: false,
              // },
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false,
              },
              pdfZoom: {
                defaultZoom: 0.6, // 1 as default,
              },
              pdfVerticalScrollByDefault: true,
            }}
            className="runbook-doc-viewer"
            documents={docs}
            initialActiveDocument={docs[0]}
            pluginRenderers={[...DocViewerRenderers]}
            key={uri}
            style={{ minHeight: "100px", width: "100%" }}
            onError={(err) => console.error("Document rendering error:", err)}
          />
        </div>
      </Row>
    </div>
  );
};

export default PolicyCategoryDetailDescription;
