import React, { useEffect, useState } from "react";
import "./cves.css";
import { DownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Row, Select, Space } from "antd";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { useNavigate } from "react-router-dom";
import { GetApi } from "../../service/http";
const { Option, OptGroup } = Select;
const Cves = ({data}) => {
  const vendors = ["AWS","AZ", "GCP", "OCI"];
  const navigate = useNavigate();
  const [vendorData, setVendorData] = useState({});
  const handleCvesCategoriesClick = (e) => {
    console.log(e);
  };
  const [selectedValue, setSelectedValue] = useState(null);
  const handleRunbookSelect = (e)=>{
    setSelectedValue(null);
  }
  const specificRunbookHandler = (e) => {
    console.log(e);
  };
  const cves_categories = [
    {
      label: <Checkbox defaultChecked={true} disabled={true}>AWS Infrastructure CVEs</Checkbox>,
      key: "1",
    },
    {
      label: <Checkbox defaultChecked={true} disabled={true}>AWS Procedural CVEs</Checkbox>,
      key: "2",
    },
    {
      label: <Checkbox defaultChecked={true} disabled={true}>Application CVEs</Checkbox>,
      key: "3",
    },
  ];
  const cvesProps = {
    items: cves_categories,
    onClick: handleCvesCategoriesClick,
  };

  const fetchVendorData = async () => {
    const fetchedData = {};
    for (const vendor of vendors) {
      try {
        const result = await GetApi("get", `runbookService/api/v1/runbook?vendor=${vendor}&category`);
        fetchedData[vendor] = result;
      } catch (error) {
        console.error(`Error fetching data for vendor ${vendor}:`, error);
      }
    }
    setVendorData(fetchedData);
  };
  useEffect(() => {
    fetchVendorData();
  }, []);
  return (
    <div className="cves-container">
      <Row className="bg-priamry font-black cves-header">
        <BreadcrumbComponent />
        {/* <p className="text-base">Coming soon </p> */}
      </Row>
      <Row className="border-primary cves-filters-button-category">
        <Button className="btn-primary">
          <p className="text-sm">All Runbooks</p>
        </Button>
        <Button>
          <p className="text-sm">Runbooks in Store</p>
        </Button>
        <Button>
          <p className="text-sm">Installed Runbooks</p>
        </Button>
        <Select 
        className="custom-select"
        value={selectedValue}
        onSelect={handleRunbookSelect}
        onChange={handleRunbookSelect}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        placeholder="Please select a Runbook" style={{minWidth:"200px"}}>
          {vendorData && vendorData["AWS"] && Object.keys(vendorData).map((vendor) => (
            <OptGroup label={vendor} key={vendor}>
              {vendorData[vendor]?.runbooks?.map((runbook) => (
                <Option  key={runbook.id} value={runbook.id}>
                  {runbook.name}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Row>
      <Row className="border-primary cves-category-filters-button-category">
        <div style={{ display: "flex", gap: "20px" }}>
          <Button>
            <p className="text-sm">Critical</p>
          </Button>
          <Button>
            <p className="text-sm">High</p>
          </Button>
          <Button className="btn-primary">
            <p className="text-sm">Medium</p>
          </Button>
        </div>
        <Dropdown menu={cvesProps}>
          <Button type="primary" className="btn-primary btn-cves-category">
            <p className="text-sm">CVE Categories</p>{" "}
          </Button>
        </Dropdown>
      </Row>
      <Row className="border-primary cve-title">
        <p className="font-sans text-sm font-bold" style={{
          fontWeight: "bold"
        }} >CVE ID & Summary</p>
        <p className="font-sans text-sm" style={{
          fontWeight: "bold"
        }}>CVSS Severity</p>
      </Row>
      <Row className="border-primary cve-description">
        <div className="cves-description-link">
          <p className="font-sans text-sm">
           <a style={{color:"rgb(1 100 127)", cursor:"pointer",     textDecoration: "underline"}} rel="noreferrer" target="_blank" href="https://nvd.nist.gov/vuln/detail/CVE-2018-15869"> CVE-2018-15869</a> - An Amazon Web Services (AWS) developer who does not
            specify the --owners flag when describing images via AWS CLI, and
            therefore not properly validating source software per AWS
            recommended security best practices, may unintentionally load an
            undesired and potentially malicious Amazon Machine Image (AMI) from
            the uncurated public community AMI catalog.
          </p>
          <p className="font-sans text-sm mt-2"><b>Published: </b> August 24, 2018</p>
          <p className="font-sans text-sm mt-2"><b>Associated Runbook(s): </b> 
          <span 
          onClick={()=>{
            navigate("/runbookstore/AWS/6?isInstalled=true")
          }}
          style={{color:"rgb(1 100 127)", cursor:"pointer",     textDecoration: "underline"}} rel="noreferrer">AWS EC2 </span>
          
          </p>
        </div>
        <div className="cves-description-medium">
          {" "}
          <p className="font-sans text-md font-primary" style={{color: '#F89D1C'}}>5.3 MEDIUM</p>
        </div>
      </Row>
    </div>
  );
};

export default Cves;
