import { Button, Card, Col, Input, Modal, Popover, Row, Divider, TreeSelect, Tabs, message, Form, Select, DatePicker, Spin } from "antd";
import React, { useState, useEffect } from "react";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import "./task.css";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { taskMockData } from "../../mockdata/mockJson";
import moment from "moment";
import { getRoleByName } from "../../utils/index";
import { GetApi } from "../../service/http";
import TaskSurvey from "../taskSurvey/taskSurvey";

const { Option, OptGroup } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
const getHRCLevel = (level) => {
  const descriptions = {
    "0": 'No HRC',
    "1": 'HRC Low',
    "2": 'HRC High',
    "3": 'HRC Very High'
  };

  return descriptions[level] || 'No HRC';
}
const Task = ({ tasks, formHandler }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [hrcLevel, setHrcLevel] = useState("-1");
  const [vendorData, setVendorData] = useState({});
  const [runbookSections, setRunbookSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [form] = Form.useForm();
  const params = new URLSearchParams(location.search);
  const tab = params.get("tab");
  const [activeTab, setActiveTab] = useState(tab);
  const [templateSelected, setTemplateSelected] = useState(false);

  const vendors = ["AWS", "GCP", "AZ", "OCI"];
  const handleTabChange = (key) => {
    setActiveTab(key);
    const params = new URLSearchParams(location.search);
    params.set("tab", key);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const transformToTreeData = (processList, parentKey = '', isTopLevel = true) => {
    return processList.map((process) => {
      const processKey = parentKey ? `${parentKey}.${process.processNumber}` : process.processNumber;
      const children = [];

      if (process.subProcesses) {
        children.push(
          ...transformToTreeData(process.subProcesses, processKey, false).map(subProcess => ({
            ...subProcess,
            disabled: true,
          }))
        );
      }

      if (process.proceduralSteps && process.proceduralSteps.length > 0) {
        children.push(
          ...process.proceduralSteps.map((step, stepIndex) => ({
            title: `${process.processNumber}.${stepIndex + 1} ${step.description}`,
            value: `${processKey}-${stepIndex + 1}`,
            key: `${processKey}-${stepIndex + 1}`,
            disabled: false,
          }))
        );
      }

      return {
        title: (
          <span style={isTopLevel ? { fontWeight: 'bold' } : {}}>
            {`${process.processNumber}. ${process.processName}`}
          </span>
        ),
        value: processKey,
        key: processKey,
        disabled: isTopLevel,
        children,
      };
    });
  };

  const handleSubmit = (values) => {
    const ownerSteps = [];

    runbookSections.forEach(section => {
      section.ownerSteps.forEach(step => {
        ownerSteps.push({
          vendorCode: section.vendorCode,
          runbookid: section.runbookId,
          runbookname: section.runbookName,
          processNumber: step.processNumber,
          processName: step.processName,
          proceduralStepDescription: step.proceduralStepDescription,
          stepIndex: step.stepIndex,
          updatedAt: step.updatedAt || '',
          reviewerupdatedAt: step.reviewerupdatedAt || ''
        });
      });

    });

    values.taskid = Math.random().toString(36).substring(7);
    const dateTimeWithTime = moment(values.createdAt).format("YYYY-MM-DD HH:mm:ss");
    values.createdAt = dateTimeWithTime;
    values.updatedAt = dateTimeWithTime;
    values.role = getRoleByName(values.assignee);
    values.status = "todo";
    values.templateId = templateSelected ? values.template : 0;
    values.templateName = templateSelected ? taskMockData.taskTemplates.find(t => t.templateId === values.template).templateName : "custom";
    values.ownerSteps = ownerSteps;
    values.hrcLevel = templateSelected ? hrcLevel : "-1";
    setOpenModal(false);
    formHandler(values);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const fetchVendorData = async () => {
    const fetchedData = {};
    for (const vendor of vendors) {
      try {
        const result = await GetApi("get", `runbookService/api/v1/runbook?vendor=${vendor}&category`);
        result.runbooks = result?.runbooks?.sort((a, b) => new Date(b.date) - new Date(a.date)) || [];
        fetchedData[vendor] = result.runbooks || [];
      } catch (error) {
        console.error(`Error fetching data for vendor ${vendor}:`, error);
      }
    }
    setVendorData(fetchedData);
  };

  const fetchProcesses = async (runbookId, vendorCode, alreadyLoading = false) => {

    !alreadyLoading && setLoading(true);
    try {
      const result = await GetApi("get", `runbookService/api/v1/runbook/${runbookId}`);
      const processes = result?.runbook?.runbookProcessList || [];
      const stepsData = flattenProcesses(processes);
      setRunbookSections(prevSections => prevSections.map(section =>
        section.runbookId === runbookId ? {
          ...section,
          stepsData,
          vendorCode
        } : section
      ));
      !alreadyLoading && setLoading(false);
      return stepsData;
    } catch (error) {
      console.error(`Error fetching processes for runbook ${runbookId}:`, error);
      !alreadyLoading && setLoading(false);
      return [];
    }
  };

  function flattenProcesses(processList) {
    let flattenedList = [];
    function recurse(process, parentKey = '') {
      const processKey = parentKey ? `${parentKey}.${process.processNumber}` : process.processNumber;
      flattenedList.push({ ...process, key: processKey });
      if (process.subProcesses && process.subProcesses.length > 0) {
        process.subProcesses.forEach(subProcess => recurse(subProcess, processKey));
      }
    }
    processList.forEach(process => recurse(process));
    return flattenedList;
  }

  useEffect(() => {
    fetchVendorData();
  }, []);

  useEffect(() => {
    if (openModal) {
      form.resetFields();
      setRunbookSections([]);
      setTemplateSelected(false);
    }
  }, [openModal, form]);

  const handleRunbookChange = async (value, index) => {
    const allRunbooks = Object.values(vendorData).flat();
    const runbook = allRunbooks.find(r => r.id === value);
    if (runbook) {
      const vendorCode = vendors.find(v => runbook.name.includes(v)) || "Unknown";
      const stepsData = await fetchProcesses(value, vendorCode);
      setRunbookSections(prevSections => prevSections.map((section, idx) =>
        idx === index ? { ...section, runbookId: value, runbookName: runbook.name, vendorCode, stepsData } : section
      ));
    } else {
      message.error("Runbook not found.");
    }
  };
  const findStepDataByKey = (stepsData, key) => {
    const [processKey, stepIndexPart] = key.split('-');
    const processParts = processKey.split('.');
    const stepIndex = parseInt(stepIndexPart, 10) - 1;

    const traverseProcesses = (processes, parts) => {
      let currentProcess = null;
      let currentProcesses = processes;

      for (let i = parts.length - 1; i >= 0; i--) {
        const processNumber = parts.slice(0, i + 1).join('.');
        currentProcess = currentProcesses.find(p => p.processNumber === processNumber);
        if (currentProcess) {
          currentProcesses = currentProcess.proceduralSteps || [];
          return currentProcess;
        }
      }

      return currentProcess;
    };

    const process = traverseProcesses(stepsData, processParts);

    if (process && process.proceduralSteps && process.proceduralSteps[stepIndex]) {
      return {
        processNumber: process.processNumber,
        processName: process.processName,
        proceduralStepDescription: process.proceduralSteps[stepIndex].description,
        stepIndex: stepIndex + 1
      };
    }

    console.error(`Procedural step not found for key: ${key}`);
    return null;
  };

  const handleOwnerStepsChange = (value, index) => {
    setRunbookSections(prevSections =>
      prevSections.map((section, idx) => {
        if (idx === index) {
          const updatedOwnerSteps = value.map(v => {
            const stepData = findStepDataByKey(section.stepsData, v);
            if (stepData) {
              return {
                ...stepData,
                value: v,
                processNumber: stepData.processNumber,
                processName: stepData.processName || 'Unknown Process Name',
                proceduralStepDescription: stepData.proceduralStepDescription || 'No description available'
              };
            } else {
              message.error("No description found.");
              return {
                value: v,
                processNumber: v.split('-')[0],
                processName: 'Unknown Process Name',
                proceduralStepDescription: 'No description available'
              };
            }
          });
          return { ...section, ownerSteps: updatedOwnerSteps };
        }
        return section;
      })
    );
  };





  const addRunbookSection = () => {
    setRunbookSections([...runbookSections, { runbookId: '', runbookName: '', vendorCode: '', ownerSteps: [], stepsData: [] }]);
  };

  const handleTemplateSelect = async (templateId) => {
    if (templateId === undefined) {
      form.resetFields();
      setRunbookSections([]);
      setTemplateSelected(false);
      setHrcLevel("-1")
    } else {
      setLoading(true)
      const template = taskMockData.taskTemplates.find(t => t.templateId === templateId);
      if (template) {
        const runbookSectionsPromises = template.runbooks.map(async runbook => {
          const vendorCode = runbook.vendorCode;
          const stepsData = await fetchProcesses(runbook.id, vendorCode, true);
          return {
            runbookId: runbook.id,
            runbookName: runbook.name,
            vendorCode,
            stepsData,
            ownerSteps: template.ownerSteps.filter(x => x.runbookid === runbook.id).map(step => ({
              value: `${step.processNumber}-${step.stepIndex}`,
              processNumber: step.processNumber,
              processName: step.processName,
              proceduralStepDescription: step.proceduralStepDescription,
              stepIndex: step.stepIndex
            })),
          };
        });

        const runbookSections = await Promise.all(runbookSectionsPromises);
        setRunbookSections(runbookSections);

        form.setFieldsValue({
          taskname: '', // Keep taskname empty to ensure it's entered manually
          description: template.description,
          assignee: template.owner,
          reviewer: template.reviewer,
          hrcLevel: template.hrcLevel,
          "awsAccount": template.awsAccount,
          "awsS3URI": template.awsS3URI,
          createdAt: moment(),
          ownerSteps: runbookSections.flatMap(section => section.ownerSteps.map(step => step.value)),
          runbook: runbookSections.map(section => ({ value: section.runbookId, label: section.runbookName }))
        });
        setHrcLevel(template.hrcLevel)
        setTemplateSelected(true);
        setLoading(false)
      }
      setLoading(false)
    }
  };

  const taskCardHandler = (taskId, taskname, taskstatus) => {
    navigate(`/task/${taskId}?taskname=${taskname}&taskstatus=${taskstatus}`);
  };

  const modalContent = (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item
        name="template"
        label="Task Template"
      >
        <Select
          showSearch
          placeholder="Select a template"
          onChange={handleTemplateSelect}
          allowClear
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {taskMockData.taskTemplates.map(template => (
            <Option key={template.templateId} value={template.templateId}>
              {template.templateName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {templateSelected &&
        <div style={{ marginBottom: "19px", marginTop: "-12px" }}>
          <span style={{ marginRight: '8px' }}>Template HRC Level: </span>
          <span>{" "} {getHRCLevel(hrcLevel)}</span>
        </div>
      }
      {templateSelected && <> <Form.Item
        name="awsAccount"
        label="Account Information"
        rules={[{ required: false, message: 'Please enter the account information' }]}
      >
        <Input />
      </Form.Item>
        <Form.Item
          name="awsS3URI"
          label="S3 Bucket Object Resource URI"
          rules={[{ required: false, message: 'Please enter the S3 bucket object resource URI' }]}
        >
          <Input />
        </Form.Item> </>}

      <Form.Item
        name="taskname"
        label="Task Name"
        rules={[{ required: true, message: "Please enter task name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="creator"
        label="Owner"
        initialValue={"Ken Williams"}
        rules={[{ required: true, message: "Please select Task Owner" }]}
      >
        <Select>
          <Option value="Ken Williams">Ken Williams</Option>
          <Option value="Olivia Perry">Olivia Perry</Option>
          <Option value="Lisa Audrey">Lisa Audrey</Option>
          <Option value="Mark Wood">Mark Wood</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="assignee"
        label="Operator"
        rules={[{ required: true, message: "Please select an Operator" }]}
      >
        <Select>
          <Option value="Ken Williams">Ken Williams</Option>
          <Option value="Olivia Perry">Olivia Perry</Option>
          <Option value="Lisa Audrey">Lisa Audrey</Option>
          <Option value="Mark Wood">Mark Wood</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="reviewer"
        label="Reviewer"
        rules={[{ required: true, message: "Please select reviewer" }]}
      >
        <Select>
          <Option value="Ken Williams">Ken Williams</Option>
          <Option value="Olivia Perry">Olivia Perry</Option>
          <Option value="Lisa Audrey">Lisa Audrey</Option>
          <Option value="Mark Wood">Mark Wood</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="description"
        label="Purpose"
        rules={[{ required: true, message: "Please enter Purpose" }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        className="date-con"
        name="createdAt"
        label="Create Date"
        initialValue={moment()}
        rules={[{ required: false, message: "Please enter create date" }]}
      >
        <DatePicker />
      </Form.Item>

      {runbookSections.map((section, index) => (
        <div key={index} style={{ marginBottom: 24 }}>
          <Form.Item
            label={`Runbook ${index + 1}`}
            rules={[{ required: true, message: 'Please select a runbook' }]}
          >
            <Select
              placeholder="Select a runbook"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => handleRunbookChange(value, index)}
              value={section.runbookId}
              disabled={templateSelected}
            >
              {Object.keys(vendorData).map((vendor) => (
                <OptGroup label={vendor} key={vendor}>
                  {vendorData[vendor].map((runbook) => (
                    <Option key={runbook.id} value={runbook.id}>
                      {runbook.name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Procedural Steps for Operator"
            rules={[{ required: true, message: 'Please select procedural steps for the operator' }]}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={section.ownerSteps.map(step => step.value)}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Please select procedural steps for the owner"
              allowClear
              multiple
              treeDefaultExpandAll
              loading={loading}
              treeData={transformToTreeData(section.stepsData)}
              onChange={(value) => handleOwnerStepsChange(value, index)}
              disabled={templateSelected}
            />
          </Form.Item>
        </div>
      ))}
      <Button
        type="dashed"
        onClick={addRunbookSection}
        style={{ width: '100%', marginBottom: 24 }}
        disabled={templateSelected}
      >
        + Select Runbook(s)
      </Button>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  const filteredTasks = (tasks, query) => {
    return tasks.filter(task => task.taskname.toLowerCase().includes(query));
  };

  return (
    <div className="tasks-container">
      <Row className="bg-priamry font-black tasks-header">
        <BreadcrumbComponent />
      </Row>
      <Tabs style={{ marginTop: "25px" }} defaultActiveKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Tasks" key="1">
          <Row className="tasks-search-and-filter-container">
            <Input className="search-tasks" placeholder="Search Tasks" onChange={(e) => {

              const query = e.target.value.toLowerCase();
              setSearchQuery(query);

            }} addonBefore={<SearchOutlined />} />
            <p style={{ fontWeight: "bold" }}>Quick Filters</p>
            <DownOutlined style={{ cursor: "pointer" }} />
            <Button className="btn-primary" onClick={() => setOpenModal(true)}>
              Create Task
            </Button>
          </Row>

          <Row className="task-parent-container">
            <Col className="task-child-container">
              <Row className="task-child-header">
                <p className="text-md font-default" style={{ fontWeight: "bold" }}>
                  {" "}
                  To Do{" "}
                </p>
              </Row>
              <Col className="task-card-container">
                {filteredTasks(tasks.todo, searchQuery).map((task, index) => (
                  <Card
                    key={index}
                    className="task-card"
                    onClick={() =>
                      taskCardHandler(task.taskid, task.taskname, task.status)
                    }
                  >
                    <Row className="task-card-body">
                      <div className="text-container">
                        <p className="text-sm">{task.taskname}</p>
                      </div>
                      <div className="task-date">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ fontWeight: "bold" }}>Created At -</span>{" "}
                          {moment(task.createdAt).format('MMMM DD, YYYY')}
                        </p>

                      </div>
                      <div className="task-user-icon">
                        <Popover title={task.assignee}>
                          <img
                            src={`/images/${getRoleByName(task.assignee)}.png`}
                            alt="Operator"
                            width="30"
                            height="30"
                          />
                        </Popover>
                      </div>
                    </Row>
                  </Card>
                ))}
              </Col>
            </Col>
            <Col className="task-child-container">
              <Row className="task-child-header">
                <p className="text-md font-default" style={{ fontWeight: "bold" }}>
                  {" "}
                  In Progress{" "}
                </p>
              </Row>
              <Col className="task-card-container">
                {filteredTasks(taskMockData.inProgress, searchQuery).map((task, index) => (
                  <Card
                    key={index}
                    className="task-card"
                    onClick={() =>
                      taskCardHandler(task.taskid, task.taskname, task.status)
                    }
                  >
                    <Row className="task-card-body">
                      <div className="text-container">
                        <p className="text-sm">{task.taskname}</p>
                      </div>
                      <div className="task-date">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ fontWeight: "bold" }}>Updated At -</span>{" "}
                          {moment(task.updatedAt, "YYYYMMDD").format('MMMM DD, YYYY')}
                        </p>
                      </div>
                      <div className="task-user-icon">
                        <Popover title={task.assignee}>
                          <img
                            src={`/images/${getRoleByName(task.assignee)}.png`}
                            alt="Operator"
                            width="30"
                            height="30"
                          />
                        </Popover>
                      </div>
                    </Row>
                  </Card>
                ))}
              </Col>
            </Col>
            <Col className="task-child-container">
              <Row className="task-child-header">
                <p className="text-md font-default" style={{ fontWeight: "bold" }}>
                  {" "}
                  In Review{" "}
                </p>
              </Row>
              <Col className="task-card-container">
                {filteredTasks(taskMockData.inReview, searchQuery).map((task, index) => (
                  <Card
                    key={index}
                    className="task-card"
                    onClick={() =>
                      taskCardHandler(task.taskid, task.taskname, task.status)
                    }
                  >
                    <Row className="task-card-body">
                      <div className="text-container">
                        <p className="text-sm">{task.taskname}</p>
                      </div>
                      <div className="task-date">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ fontWeight: "bold" }}>Updated At -</span>{" "}
                          {moment(task.updatedAt, "YYYYMMDD").format('MMMM DD, YYYY')}
                        </p>
                      </div>
                      <div className="task-user-icon">
                        <Popover title={task?.reviewer}>
                          <img
                            src={`/images/${getRoleByName(task?.reviewer)}.png`}
                            alt="Operator"
                            width="30"
                            height="30"
                          />
                        </Popover>
                      </div>
                    </Row>
                  </Card>
                ))}
              </Col>
            </Col>
            <Col className="task-child-container">
              <Row className="task-child-header">
                <p className="text-md font-default" style={{ fontWeight: "bold" }}>
                  {" "}
                  Done{" "}
                </p>
              </Row>
              <Col className="task-card-container">
                {filteredTasks(taskMockData.done, searchQuery).map((task, index) => (
                  <Card
                    key={index}
                    className="task-card"
                    onClick={() =>
                      taskCardHandler(task.taskid, task.taskname, task.status)
                    }
                  >
                    <Row className="task-card-body">
                      <div className="text-container">
                        <p className="text-sm">{task.taskname}</p>
                      </div>
                      <div className="task-date">
                        <p style={{ fontSize: "12px" }}>
                          <span style={{ fontWeight: "bold" }}>Updated At -</span>{" "}
                          {moment(task.updatedAt, "YYYYMMDD").format('MMMM DD, YYYY')}
                        </p>
                      </div>
                      <div className="task-user-icon">
                        <Popover title={task.assignee}>
                          <img
                            src={`/images/${getRoleByName(task.assignee)}.png`}
                            alt="Operator"
                            width="30"
                            height="30"
                          />
                        </Popover>
                      </div>
                    </Row>
                  </Card>
                ))}
              </Col>
            </Col>
          </Row>

          <Modal
            title={"Create a new task"}
            open={openModal}
            onCancel={handleCancel}
            footer={null}
          >
            <Spin spinning={loading}>
              {modalContent}
            </Spin>
          </Modal>
        </TabPane>
        <TabPane tab="Templates" key="2">
          <div>
            <TaskSurvey></TaskSurvey>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Task;
