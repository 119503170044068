// Communication with backend services
const getbasicUrl = {
  runbookService: "http://ec2-54-81-110-182.compute-1.amazonaws.com/dashboard-service",
}

export const getBaseUrl = () => {
  const environment = process.env.NODE_ENV || 'development';
  if (environment !== 'production') {
    return getbasicUrl.runbookService;
  } else {
    const { protocol, hostname, port } = window.location;
    const host = port ? `${hostname}:${port}` : hostname;
    if (host.includes('surge.sh')) {
      return getbasicUrl.runbookService;
    } else {
      return `${protocol}//${host}/dashboard-service`;
    }
  }
};
export const APIConfig = {
  baseURL: () => {
    return getBaseUrl()
  }
}

export const callApi = (servicename) => {
  return getBaseUrl();
}