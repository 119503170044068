import React from "react";
import { useEffect } from "react";
import Vendor from "../../component/vendor/vendor";
import { GetApi } from "../../service/http";
import { useState } from "react";

const Index = ({  pathname, isInstalled }) => {
  const [data,setData ] = useState();
  const [categoryData, setCategoryData] = useState();

  const fetchData = async()=>{
    const result = await GetApi("get", `runbookService/api/v1/vendors?installed=${isInstalled}`);
    setData(result)
    const categoryresult = await GetApi(
      "get",
      `runbookService/api/v1/categories`
    );
    setCategoryData(categoryresult);
  }
  const getApiHandler = () => {
    fetchData();
  };
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line
  }, [pathname]);
   return <Vendor pathname={pathname} isInstalled={isInstalled} data={data}   categoryData={categoryData} getApiHandler={getApiHandler} />;
};

export default Index;
