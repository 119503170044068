import { Collapse, Row } from "antd";
import React from "react";
import { RightOutlined  } from "@ant-design/icons";
import { Skeleton } from "antd";

const SubProcessChild = ({
  subprocess,
  index,

  active,

  outlineHanlder,
}) => {
  const { Panel } = Collapse;
  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {subprocess?.subProcesses?.length > 0 ? (
        <Collapse
          style={{
            width: "100%",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className={`outline-container ${
            arraysAreEqual(active, index) ? "active" : ""
          }`}
          defaultActiveKey={subprocess?.subProcesses?.length === 0 ? [] : ["0"]}
          title={subprocess?.processName}
          onChange={() => outlineHanlder(index)}
        >
        {subprocess ?  <Panel
            style={{ padding: "0px" }}
            header={`${subprocess?.processNumber} ${". "} ${
              subprocess?.processName
            }`}
            title={subprocess?.processName}
          >
            {subprocess?.subProcesses?.map((subprocess, subindex) => (
              <Row>
                <SubProcessChild
                 key={`${index}-${subindex}` }
                  subprocess={subprocess}
                  index={[...index, subindex]}
                  active={active}
                  outlineHanlder={outlineHanlder}
                />
              </Row>
            ))}
          </Panel>  : (
          <Skeleton active style={{margin:"30px"}}/>
        )}
        </Collapse>
      ) : (
        <Row
          className={`border-primary outline-container ${
            arraysAreEqual(active, index) ? "active" : ""
          } `}
          style={{
            gap: "10px",
            width: "100%",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            alignItems: "center",
            padding: "10px",
          }}
          onClick={() => outlineHanlder(index)}
        >
         {subprocess ? <p
            className="font-default font-sans "
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginLeft: "8px",
            }}
          >
            <RightOutlined  style={{ fontSize: "11px", marginRight: "10px" }} />{" "}
            {subprocess?.processNumber}
            {". "}
            {subprocess?.processName}{" "}
          </p> : (
          <Skeleton active paragraph={30} style={{margin:"30px"}}/>
        )}
        </Row>
      )}
    </>
  );
};

export default SubProcessChild;
