import { Card, Input, Row, Select, Space, Spin, Table, notification } from "antd";
import React, { useState, useEffect } from "react";
import "./installRunbook.css";
import { SearchOutlined } from "@ant-design/icons";

import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { useNavigate } from "react-router-dom";
import { MethodApi } from "../../service/http";

const InstallRunbook = ({ data, vendorcode, getApiHandler }) => {
  const [searchText, setSearchText] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [runbookID, setRunbookID] = useState();

  const navigate = useNavigate();
  const installedRunbookHandler = (id) => {
    navigate(`/installrunbook/${vendorcode}/${id}?isInstalled=${true}`);
  };
  const uninstallRunbookHandler = (runbookId) => {
    setRunbookID(runbookId);
    setLoading(true);
    MethodApi({
      method: "delete",
      pathname: `runbookService/api/v1/runbook/${runbookId}`,
      onSuccess: () => {
        
        api["success"]({
          message: "Uninstalled Successfully",
          duration: 2,
        });
        getApiHandler();
      },
      onError: (error) => {
     
        api["error"]({
          message: error?.message || "Uninstall Runbook failed",
          duration: 2,
        });
        getApiHandler();
      },
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
      align: "left",
      render: (text, record) => (
        <Space size="middle">
          <p href="" onClick={() => installedRunbookHandler(record?.id)}>
            {" "}
            <p
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "rgb(1 100 127)",
              }}
            >
              {text}
            </p>{" "}
          </p>
        </Space>
      ),
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      align: "center",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 150,
    },
    // {
    //   title: "CVEs ",
    //   dataIndex: "cves",
    //   key: "address",
    //   align: "center",
    //   width: 150,

    //   render: (text, record) => (
    //     <Space size="middle">
    //      <p>{record.id===22 ?1 : 0}</p>
    //     </Space>
    //   ),
    // },
    {
      title: "Processes",
      dataIndex: "processes",
      key: "processes",
      align: "center",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts.processes}</p>
        </Space>
      ),
    },
    {
      title: "NIST CSF Policies ",
      dataIndex: "policies",
      key: "nist_csf_policies",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts.policies}</p>
        </Space>
      ),
    },
    {
      title: "NIST CSF Subcategories",
      dataIndex: "subcategories",
      key: "nist_csf_sub_categories",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts.subcategories}</p>
        </Space>
      ),
    },
    {
      title: "NIST 800-53 Controls",
      dataIndex: "controls",
      key: "nist_800_controls",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <p>{record?.counts.controls}</p>
        </Space>
      ),
    },
    {
      title: "",
      key: "uninstall",
      align: "center",
      width: 120,

      render: (text, record) => (
        <Space
          size="middle"
          title="Uninstall"
          onClick={() => uninstallRunbookHandler(record?.id)}
        >
          {record?.id === runbookID && loading && (
            <Spin spinning={loading}></Spin>
          )}
          {!loading && (
            <img
              src="/images/uninstall.png"
              alt="uninstall-icon"
              height={30}
              width={20}
              style={{ cursor: "pointer" }}
            />
          )}
        </Space>
      ),
    },
  ];
  const handleSearch = (value) => {
    setSearchText(value);
  };
  const filtered = data?.runbooks?.filter((runbook) =>
    runbook.name.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    setLoading(false);
  }, [data]);
  return (
    <div className="install-runbook-container">
      {contextHolder}
      <Row className="bg-priamry font-black install-runbook-header">
        <BreadcrumbComponent />
      </Row>
      <Row className="install-runbook-card-container">
        <Card className="install-runbookCard">
          <p className="text-3xl">{data?.runbooks?.length || 0}</p>
          <p className="text-lh"> Installed Runbooks</p>
        </Card>
        {/* <Card className="install-runbookCard">
          <p className="text-3xl" >
            {vendorcode === "AWS" ? 1 : 0}
          </p>
          <p className="text-lh">Common Vulnerabilities and Exposures</p>
        </Card> */}
        {/* <Card className="install-runbookCard">
          <p className="text-3xl" style={{ color: "rgb(250 184 63)" }}>
            76%
          </p>
          <p className="text-lh">Compliance</p>
        </Card> */}
      </Row>

      <Row className="p-2 mt-4 mb-4 install-search-container">
        <Input
          className="search-install-runbooks"
          addonBefore={<SearchOutlined />}
          placeholder="Search By Name"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {/* <Select style={{minWidth:"150px"}} placeholder="Resiliency">
            <Select.Option value={"low"}>LOW</Select.Option>
            <Select.Option value={"medium"}>MEDIUM</Select.Option>
            <Select.Option value={"high"}>HIGH</Select.Option>
        </Select> */}
      </Row>
      <Table
        className="mt-2 install-runbook-table"
        dataSource={filtered}
        columns={columns}
        scroll={{ x: "max-content" }}
        rowKey={"id"}
        sticky
        pagination={{ defaultPageSize: 20 }}
      />
    </div>
  );
};

export default InstallRunbook;
