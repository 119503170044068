import React, { useState } from "react";
import "./leftSider.css";
import { useNavigate } from "react-router-dom";
import { FaTasks } from "react-icons/fa";
import { GrVirtualMachine } from "react-icons/gr";
import { RiGovernmentFill } from "react-icons/ri";
import { AiOutlineAudit } from "react-icons/ai";
import { SettingOutlined, DownloadOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { MdLocalMall } from "react-icons/md";
const LeftSider = ({ routeSidebarHandler }) => {
  const menu = (
    <Menu>
      <Menu.Item key="ad">Admin</Menu.Item>
      <Menu.SubMenu key="integration" title="Integrations">
        <Menu.Item key="wiz">Wiz</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="pr">Help</Menu.Item>
    </Menu>
  );

  const navigate = useNavigate();
  const [active, setActive] = useState(window.location.pathname);
  const activeClassHandlder = (activename) => {
    switch (activename) {
      case "dashboard":
        navigate("/installrunbook");
        break;
      case "home":
        navigate("/");
        break;
      case "evaluate":
        navigate("/evaluate");
        break;
      case "runbook":
        navigate("/runbookstore");
        break;
      case "nist_csf":
        navigate("/nist_csf");
        break;
      case "cves":
        navigate("/cves");
        break;
      case "policy":
        navigate("/policy");
        break;
      case "task":
        navigate("/task");
        break;
      case "governance":
        navigate("/governance");
        break;
      case "audit":
        navigate("/audit");
        break;
      default:
        break;
    }
    setActive(window.location.pathname);
    routeSidebarHandler();
  };
  return (
    <div style={{ position: 'relative', height: "100%" }}>
      <div className="sider-parent-div">
      <div
          className={`sider-child-div sider-child-primary font-default ${active.endsWith("/") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("home")}
        >
          <svg
            className={`font-default ${active === "/" || active.endsWith("/")
              ? "active-icons"
              : ""
              }`}
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 24 26"
          >
            <path d="M8.44553 23.9779V20.1519C8.44553 19.1752 9.24309 18.3834 10.2269 18.3834H13.8233C14.2958 18.3834 14.7489 18.5697 15.083 18.9014C15.417 19.233 15.6047 19.6828 15.6047 20.1519V23.9779C15.6017 24.384 15.7621 24.7744 16.0503 25.0626C16.3385 25.3508 16.7306 25.5128 17.1396 25.5128H19.5933C20.7392 25.5158 21.8392 25.0659 22.6506 24.2626C23.4619 23.4592 23.9179 22.3683 23.9179 21.2307V10.3308C23.9179 9.4119 23.5076 8.54024 22.7975 7.95067L14.4507 1.33289C12.9988 0.172562 10.9185 0.210026 9.50989 1.42186L1.35356 7.95067C0.609958 8.52286 0.165518 9.39711 0.143555 10.3308V21.2196C0.143555 23.5907 2.07977 25.5128 4.4682 25.5128H6.8658C7.71535 25.5128 8.40577 24.8324 8.41192 23.9891L8.44553 23.9779Z" />
          </svg>
          <span className="text-lh font-sans">Home</span>
        </div>
        <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/evaluate") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("evaluate")}
        >
          <GrVirtualMachine size={20} />
          <span className="text-lh font-sans">Evaluate</span>
        </div>
        <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/installrunbook") || active.startsWith("/installrunbook") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("dashboard")}
        >
          <MdLocalMall size={20} />
          <span className="text-lh font-sans">Runbooks</span>
        </div>
        {/* <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/runbookstore") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("runbook")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 24 26"
            className={`font-default ${active.startsWith("/runbookstore") ? "active-icons" : ""
              }`}
          >
            <path d="M17.2749 0.487183C21.1401 0.487183 23.2923 2.71446 23.2923 6.53087V19.4566C23.2923 23.3356 21.1401 25.5128 17.2749 25.5128H6.78789C2.984 25.5128 0.769226 23.3356 0.769226 19.4566V6.53087C0.769226 2.71446 2.984 0.487183 6.78789 0.487183H17.2749ZM7.12574 17.6798C6.75035 17.6423 6.38748 17.8174 6.18728 18.1428C5.98707 18.4556 5.98707 18.8685 6.18728 19.1938C6.38748 19.5067 6.75035 19.6944 7.12574 19.6443H16.9358C17.4351 19.5943 17.8117 19.1676 17.8117 18.6683C17.8117 18.1553 17.4351 17.7298 16.9358 17.6798H7.12574ZM16.9358 11.9727H7.12574C6.58644 11.9727 6.14974 12.4119 6.14974 12.95C6.14974 13.488 6.58644 13.926 7.12574 13.926H16.9358C17.4738 13.926 17.9118 13.488 17.9118 12.95C17.9118 12.4119 17.4738 11.9727 16.9358 11.9727ZM10.8658 6.30564H7.12574V6.31816C6.58644 6.31816 6.14974 6.75611 6.14974 7.29416C6.14974 7.83221 6.58644 8.27016 7.12574 8.27016H10.8658C11.4051 8.27016 11.8431 7.83221 11.8431 7.28039C11.8431 6.74359 11.4051 6.30564 10.8658 6.30564Z" />
          </svg>{" "}
          <span className="text-lh font-sans"> Runbook Store </span>
        </div> */}
        {/* <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/nist_csf") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("nist_csf")}
        >
          <svg
            className={`font-default ${active.startsWith("/nist_csf") ? "active-icons" : ""
              }`}
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="19"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M479.07 111.36a16 16 0 0 0-13.15-14.74c-86.5-15.52-122.61-26.74-203.33-63.2a16 16 0 0 0-13.18 0C168.69 69.88 132.58 81.1 46.08 96.62a16 16 0 0 0-13.15 14.74c-3.85 61.11 4.36 118.05 24.43 169.24A349.47 349.47 0 0 0 129 393.11c53.47 56.73 110.24 81.37 121.07 85.73a16 16 0 0 0 12 0c10.83-4.36 67.6-29 121.07-85.73a349.47 349.47 0 0 0 71.5-112.51c20.07-51.19 28.28-108.13 24.43-169.24zm-131 75.11-110.8 128a16 16 0 0 1-11.41 5.53h-.66a16 16 0 0 1-11.2-4.57l-49.2-48.2a16 16 0 1 1 22.4-22.86l37 36.29 99.7-115.13a16 16 0 0 1 24.2 20.94z"></path>
          </svg>
          <span className="text-lh font-sans">CSF</span>
        </div> */}
        {/* <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/cves") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("cves")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 20 25"
            className={`font-default ${active.startsWith("/cves") ? "active-icons" : ""
              }`}
          >
            <path d="M10 0C6.33333 0 3.33333 2.81124 3.33333 6.2472H6.66667C6.66667 4.49798 8.13333 3.1236 10 3.1236C11.8667 3.1236 13.3333 4.49798 13.3333 6.2472V12.4944H0V24.9888H20V12.4944H16.6667V6.2472C16.6667 2.81124 13.6667 0 10 0Z" />
          </svg>
          <span className="text-lh font-sans"> CVEs</span>
        </div> */}
       
        <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/task") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("task")}
        >
          <FaTasks size={20} />
          <span className="text-lh font-sans">Tasks</span>
        </div>
        <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/policy") ? "active" : ""
            }`}
          onClick={() => activeClassHandlder("policy")}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="19"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M272 41.69V188a4 4 0 0 0 4 4h146.31a2 2 0 0 0 1.42-3.41L275.41 40.27a2 2 0 0 0-3.41 1.42z"></path>
            <path d="M248 224a8 8 0 0 1-8-8V32H92a12 12 0 0 0-12 12v424a12 12 0 0 0 12 12h328a12 12 0 0 0 12-12V224zm104 160H160v-32h192zm0-80H160v-32h192z"></path>
          </svg>
          <span className="text-lh font-sans"> Policies</span>
        </div>
        <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/governance") ? "active" : ""
            }`}
          style={{ color: "grey" }}
          onClick={() => activeClassHandlder("governance")}
        >
          <RiGovernmentFill size={20} />
          <span className="text-lh font-sans">Governance</span>
        </div>
        <div
          className={`sider-child-div sider-child-primary font-default ${active.startsWith("/audit") ? "active" : ""
            }`}
          style={{ color: "grey" }}
          onClick={() => activeClassHandlder("audit")}
        >
          <AiOutlineAudit size={21} />
          <span className="text-lh font-sans" >Audit</span>
        </div>
      </div>
      <div className="menu-settings">
        <Dropdown overlay={menu} placement="topLeft" trigger={['click']}>
          <SettingOutlined style={{ fontSize: '24px', position: 'absolute', bottom: '20px', right: '20px', cursor: 'pointer' }} />
        </Dropdown>
      </div>
    </div>
  );
};

export default LeftSider;
