import React from "react";
import "./policy.css";
import {  Row, Tabs } from "antd";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import Policyv1 from "./policyv1.1";
const Policy = ({data}) => {
  const items = [
    {
      key: "1",
      label: "NIST CSF v1.1",
      children: <Policyv1 data={data} />,
    },
    {
      key: "2",
      label: "NIST CSF v2.0",
      children: "Content of Tab Pane 2",
      disabled: true,
    },
  ];
  

  return (
    <div className="policy-container">
      <Row className="bg-priamry font-black policy-header">
        <BreadcrumbComponent />
      </Row>
      <Tabs defaultActiveKey="1" items={items}  />
      
    </div>
  );
};

export default Policy;
