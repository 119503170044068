import { Card, Col, Divider, Empty, Row } from "antd";
import React from "react";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./csf_subcategory.css";
const CsfSubcategory = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);
  const subcategoryname = queryParams.get("subcategoryname");
  const category = queryParams.get("category");
  const runbookDetailHandler = (runbookid, processnumber) => {
    navigate(
      `/nist_csf/${
        params?.categoryId
      }/${params.subcategoryId}/${runbookid}?isInstalled=${true}&category=${category}&subcategoryname=${subcategoryname}&processnumber=${processnumber}`
    );
  };
  const getUniqueProcesses = (processes) => {
    const seen = new Set();
    return processes?.filter(process => {
        const isDuplicate = seen?.has(process.processnumber);
        seen.add(process?.processnumber);
        return !isDuplicate;
    });
};
const policyDetailDescriptoinHandler = (policyDescriptionId, policyname) => {
  navigate(
    `/nist_csf/${
      params?.categoryId
    }/${params.subcategoryId}/policy/${policyDescriptionId}?category=${category}&categoryname=${subcategoryname}&policyname=${policyname}`
  );
};
const uniqueProcesses = getUniqueProcesses(data?.processes);
  return (
    <div className="csfCategory-detail-container">
      <Row className="bg-priamry font-black csfCategory-detail-header">
        <BreadcrumbComponent category={category} subcategoryname={subcategoryname} />
      </Row>
      <Card
        title={
          <Row className="font-secondary">
            <Col
              align="left"
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              <p className={`summary-category category-options-${category}`}>
                {category}
              </p>
              <p className="font-sans text-sm">{subcategoryname}</p>
            </Col>
          </Row>
        }
        className="csfCategory-detail-card bg-secondary"
      >
        <Col style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
         
            <Row>
              <Col span={24} align="left">
                <p className="text-sm lg:text-xs mt-2 font-bold">Policies</p>
                <Divider />
                {data?.policies ? data?.policies?.map((policy, index) => (
                  <p
                    key={index}
                    className="text-sm  lg:text-xs"
                    style={{ cursor: "pointer", color: "rgb(1 100 127)" , lineHeight: "25px", }}
                    onClick={() => policyDetailDescriptoinHandler(policy?.policyid,policy?.policyname)}
                  >
                    {policy.policyname}
                  </p>
                ))  : <p>No Policies Found</p>}
              </Col>
            </Row>
          

          {data?.processes?.length > 0 && (
            <Row>
              <Row style={{ width: "100%" }}>
                <Col span={12} align="left">
                  <p className="text-sm font-bold lg:text-xs">Processes</p>
                </Col>
                <Col span={12} align="right">
                  <p className="text-sm font-bold lg:text-xs">Runbooks</p>
                </Col>
              </Row>
              <Divider />
              {uniqueProcesses?.map((process, index) => (
                <Row key={index} style={{ width: "100%" }}>
                  <Col span={12} align="left">
                    <p
                      className="text-sm  lg:text-xs"
                      style={{ cursor: "pointer", color: "rgb(1 100 127)" }}
                      onClick={() => runbookDetailHandler(process?.runbookid,process?.processnumber)}
                    >
                     {process?.processname}
                    </p>
                  </Col>
                  <Col span={12} align="right">
                    <p
                      className="text-sm  lg:text-xs"
                      style={{ cursor: "pointer", color: "rgb(1 100 127)" }}
                      onClick={() => runbookDetailHandler(process?.runbookid, process?.processnumber)}
                    >
                      {process.runbookname}
                    </p>
                  </Col>
                </Row>
              ))}
            </Row>
          )}
        </Col>
      </Card>
    </div>
  );
};

export default CsfSubcategory;
