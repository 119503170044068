import { Button, Col, Form, Upload } from "antd";

import { UploadOutlined } from "@ant-design/icons";
const ImportModalContent = ({ finishHandler }) => {
  const onFinish = (values) => {
    finishHandler(values);
  };
  return (
    <Col style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Form onFinish={onFinish}>
        <Form.Item name="file">
          <Upload>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            className="btn-primary"
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
};
export default ImportModalContent;
