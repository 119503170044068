import { Row, Col, Button, Modal, Select, Divider, Popover, Empty, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import "./notes.css";
const { Option } = Select;

const NotesSection = ({ task, setReloadKey, taskMockData, tskStatus }) => {
  const defaultValue = task.status === 'inReview' ? task?.reviewer : task?.assignee;
  const [noteText, setNoteText] = useState("");
  const [selectedUser, setSelectedUser] = useState(defaultValue);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewNote, setViewNote] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const notes = task.notes || [];

  useEffect(() => {
    if (!isModalVisible) {
      setNoteText("");
      setSelectedUser(defaultValue);
      setEditIndex(null);
    }
  }, [isModalVisible, defaultValue]);

  const showModal = (index = null) => {
    setIsModalVisible(true);
    setLoading(true);
    if (index !== null) {
      const note = notes[index];
      setEditIndex(index);
      setTimeout(() => {
        setNoteText(note.text);
        setSelectedUser(note.addedBy);
        setLoading(false);
      }, 1000);
    } else {
      setNoteText("");
      setSelectedUser(defaultValue);
      setEditIndex(null);
      setLoading(false);
    }
  };

  const handleOk = () => {
    if (noteText && selectedUser) {
      const newNote = {
        text: noteText,
        addedBy: selectedUser,
        createdAt: moment().format('MMMM DD, YYYY'),
        updatedAt: moment().format('MMMM DD, YYYY')
      };
      let updatedNotes;
      if (editIndex !== null) {
        updatedNotes = [...notes];
        updatedNotes[editIndex] = newNote;
      } else {
        updatedNotes = [...notes, newNote];
      }
      task.notes = updatedNotes;
      taskMockData.updateTaskNotes(task.taskid, moment(), task.notes);
      setReloadKey((prevKey) => prevKey + 1);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteNote = (index) => {
    const updatedNotes = notes.filter((_, i) => i !== index);
    task.notes = updatedNotes;
    taskMockData.updateTaskNotes(task.taskid, moment(), task.notes);
    setReloadKey((prevKey) => prevKey + 1);
  };

  const showNote = (note) => {
    setLoading(true);
    setViewNote(note);
    setTimeout(() => {
      setNoteText(note.text);
      setLoading(false);
    }, 1000);
  };

  const truncateString = (str, maxLength) => {
    const div = document.createElement('div');
    div.innerHTML = str;
    const text = div.textContent || div.innerText || "";
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const modules = {
    toolbar: [
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']                                    
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'color', 'background'
  ];

  return (
    <div>
      <Row>
        <Col span={12} align="left">
          <p className="text-sm font-bold lg:text-xs">Notes</p>
        </Col>
        <Col span={12} align="right">
          <Button onClick={() => showModal()}>Add Note</Button>
        </Col>
      </Row>
      
      {notes.length ? (
        <>
          <Divider />
          <Row style={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>
            <Col span={6}>Note</Col>
            <Col span={4}>Created By</Col>
            <Col span={4}>Created At</Col>
            <Col span={4}>Updated At</Col>
            <Col span={6} align="right"></Col>
          </Row>
          <Divider />
          {notes.map((note, index) => (
            <Row key={index} style={{ marginBottom: '10px', textAlign: 'left' }}>
              <Col span={6} align="left">
                <div onClick={() => showNote(note)}>
                  <p className="text-sm lg:text-xs"
                    style={{
                      cursor: "pointer",
                      color: "rgb(1 100 127)",
                    }}>{truncateString(note.text, 40)}</p>
                </div>
              </Col>
              <Col span={4} align="left">
                <p>{note.addedBy}</p>
              </Col>
              <Col span={4} align="left">
                <p>{note.createdAt}</p>
              </Col>
              <Col span={4} align="left">
                <p>{note.updatedAt}</p>
              </Col>
              <Col span={6} align="right">
                <Popover title="Edit Note">
                  <EditOutlined
                    title="Edit"
                    onClick={() => showModal(index)}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "blue",
                      marginRight: "10px"
                    }}
                  />
                </Popover>
                <Popover title="Delete Note">
                  <DeleteOutlined
                    title="Delete"
                    onClick={() => handleDeleteNote(index)}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "red",
                    }}
                  />
                </Popover>
              </Col>
            </Row>
          ))}
        </>
      ) : (
        <>
        <Divider style={{borderBottom: "1px solid rgba(0, 0, 0, 0.4)"}} />
        <Empty description="No notes added yet" />
        </>
      )}
      <Modal
        title={editIndex !== null ? "Edit Note" : "Add New Note"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="quill-model"
        afterClose={() => setNoteText("")}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <ReactQuill
              value={noteText}
              onChange={(htl)=>{setNoteText(htl)}}
              placeholder="Add your note here"
              className="mb-3 mt-3"
              theme="snow"
              modules={modules}
              formats={formats}
              style={{ minHeight: '200px' }}
            />
            <Select
              style={{ width: "100%", marginTop: "10px" }}
              placeholder="Select a user"
              value={selectedUser}
              onChange={(value) => setSelectedUser(value)}
            >
              <Option value="Ken Williams">Ken Williams</Option>
              <Option value="Olivia Perry">Olivia Perry</Option>
              <Option value="Lisa Audrey">Lisa Audrey</Option>
              <Option value="Mark Wood">Mark Wood</Option>
            </Select>
          </>
        )}
      </Modal>
      {viewNote && (
        <Modal
          title="View Note"
          open={!!viewNote}
          footer={null}
          className="quill-model"
          onCancel={() => setViewNote(null)}
        >
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <ReactQuill
                defaultValue={noteText}
                value={noteText}
                readOnly={true}
                theme="snow"
                modules={modules}
                formats={formats}
                className="mb-3 mt-3"
                style={{ minHeight: '200px', border: '1px solid #ddd' }}
              />
              <p className="mb-3"><b>Added by:</b> {viewNote.addedBy}</p>
              <p className="mb-3"><b>Created At:</b> {viewNote.createdAt}</p>
              <p className="mb-3"><b>Updated At:</b> {viewNote.updatedAt}</p>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default NotesSection;
