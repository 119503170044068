import React from "react";
import "./policyCategory.css";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { Card, Col, Row } from "antd";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {getBreadcrumbCategoryValue} from "../../utils/index"
const PolicyCategory = ({data}) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryname = queryParams.get("categoryname");
  const policyCategoryValue = getBreadcrumbCategoryValue(params?.id.split(".")[0])
  const policyDetailHandler = (policyDetailId, policyCategoryname, policies) => {
   
      navigate(`/policy/${params?.id}/${policyDetailId}?categoryname=${categoryname}&policyCategoryname=${policyCategoryname}&policies=${policies}`);
   
  };
  const calculatePoliciesSum = () => {
    var sum = data?.entries?.reduce((sum, entry) => {
      const policiesValue = isNaN(entry.policies) ? 0 : entry.policies;
      return sum + policiesValue;
  }, 0);
  sum = isNaN(sum) ? '-' : sum
  return sum
  };
  return (
    <div className="policy-category-container">
      <Row className="bg-priamry font-black policy-category-header">
        <BreadcrumbComponent categoryName={categoryname}  />
      </Row>
      <Card
        title={
          <Row className="font-secondary">
            <Col
              span={18}
              align="left"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className={`summary-category category-options-${policyCategoryValue}`}
              >
                {policyCategoryValue}
              </p>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <p className="text-sm lg:text-xs mr-3">
                {calculatePoliciesSum()}
              </p>
              <span className="text-sm lg:text-xs">Policies</span>
            </Col>
          </Row>
        }
        className="policy-category-card bg-secondary"
      >
        {data?.entries?.map((entry, index) => (
          <Row key={index} className="font-default p-1">
            <Col span={18} align="left">
              <p
                className="text-sm  lg:text-xs"
                style={{
                  color: "rgb(1 100 127)" ,
                  cursor:  "pointer" ,
                }}
                onClick={() => policyDetailHandler(entry?.subcategoryid , entry?.subcategoryname , entry?.policies)}
              >
                {entry.subcategoryid} :- {entry?.subcategoryname}
              </p>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <p
                className="text-sm lg:text-xs mr-3"
                style={{
                  color:  "rgb(1 100 127)" ,
                  cursor:  "pointer" ,
                }}
              >
                {entry.policies}{" "}
              </p>
            </Col>
          </Row>
        ))}
      </Card>
    </div>
  );
};

export default PolicyCategory;
