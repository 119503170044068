import { Card, Col, Row, Tag } from "antd";
import { getBreadcrumbCategoryValue } from "../../utils";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import "./control.css";
const Control = ({ data, handlePillClick }) => {

  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var controlname = queryParams.get("controlname");
  var categoryname = queryParams.get("categoryname");
  const policyCategoryValue = getBreadcrumbCategoryValue(
    params?.policyCategoryId?.split(".")[0]
  );
  controlname = data?.control[0]?.controlname || controlname
  return (
    <div className="control-category-container">
      <Row className="bg-priamry font-black control-category-header">
        <BreadcrumbComponent categoryName={categoryname} controlname={controlname} />
      </Row>
      <Card
        title={
          <Row className="font-secondary">
            <Col
              span={18}
              align="left"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p
                className={`summary-category category-options-${policyCategoryValue}`}
              >
                {policyCategoryValue}
              </p>
            </Col>
          </Row>
        }
        className="control-category-card bg-secondary"
      >
        {data?.control?.map((entry, index) => (
          <Row key={index} className="font-default p-1">
            <Col align="left" style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              {entry?.controlname && <> <Row>
                <p className="font-primary font-sans text-sm font-bold">Control Name:</p>
              </Row>
                <Row>
                  <p className="font-default font-sans text-sm">
                    {entry?.controlname}
                  </p>
                </Row> </>}
              {entry?.controltext && <>  <Row>
                <p className="font-primary font-sans text-sm font-bold">Control Text:</p>
              </Row>
                <Row>
                  <p className="font-default font-sans text-sm">
                    {entry?.controltext}
                  </p>
                </Row></>}
              {entry?.controldesc && (
                <>
                  <Row>
                    <p className="font-primary font-sans text-sm font-bold">
                      Control Description:
                    </p>
                  </Row>
                  <Row>
                    <p className="font-default font-sans text-sm">
                      {entry?.controldesc}
                    </p>
                  </Row>{" "}
                </>
              )}
              {entry?.hrm && (
                <>
                  {" "}
                  <Row>
                    <p className="font-primary font-sans text-sm font-bold">
                      Control HRM:
                    </p>
                  </Row>
                  <Row>
                    <p className="font-default font-sans text-sm">
                      {entry?.hrm}
                    </p>
                  </Row>
                </>
              )}
              {/* <Row>
                <p className="font-primary font-sans text-sm font-bold">
                  Related Controls:
                </p>
              </Row>
              <Row style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {entry?.relatedcontrols.split(",")?.map((pill, index) => (
                  <p
                    className="font-primary"
                    key={index}
                    onClick={() => handlePillClick(pill)}
                    style={{ cursor: 'pointer' }}
                  >
                    {pill}
                  </p>
                ))}
              </Row> */}
            </Col>
          </Row>
        ))}
      </Card>
    </div>
  );
};

export default Control;
