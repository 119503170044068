import React from 'react';
import {  Button, Row } from 'antd';
import BreadcrumbComponent from '../breadcrumb/breadcrumb'
import "./home.css";
const Home = () => {
  
  return (
    <div className="tasks-container">
      <Row className="bg-priamry font-black tasks-header-eval">
        <BreadcrumbComponent />
      </Row>
      <div style={{marginTop: '25px', textAlign:'left'}}>
      <Button className='btn-primary' type="primary" disabled={true} style={{marginBottom: "15px"}}>Configure</Button>
    </div>
    </div>
  );
};

export default Home;
