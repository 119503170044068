import Governance from '../../component/governance/governance'
import React from 'react'

const Index = () => {
  return (
    <Governance />
  )
}

export default Index
