import { Empty, Row, Modal, Form, Input, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../breadcrumb/breadcrumb';
import './governance.css';
import moment from 'moment';
import { Tabs, List, Button, Timeline, message } from 'antd';
import { CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;
const { Item: TimelineItem } = Timeline;
const { Option } = Select;

const attendeeList = [
  "Ken Williams",
  "Olivia Perry",
  "Lisa Audrey",
  "Mark Wood",
];
const governanceBoardsData = {
  "Cyber Resiliency Board (CRB)": [
    {
      id: 1,
      startDate: "2024-04-20",
      eventName: "CRB Meeting April 20, 2024",
      attendees: ["Ken Williams", "Olivia Perry", "Lisa Audrey"]
    },
    {
      id: 2,
      startDate: "2024-04-25",
      eventName: "CRB Meeting April 25, 2024",
      attendees: ["Ken Williams",  "Lisa Audrey", "Mark Wood"]
    },
    {
      id: 3,
      startDate: "2024-05-30",
      eventName: "CRB Meeting May 30, 2024",
      attendees: ["Ken Williams",  "Lisa Audrey"]
    },
    {
      id: 4,
      startDate: "2024-06-30",
      eventName: "CRB Meeting June 30, 2024",
      attendees: ["Ken Williams", "Lisa Audrey", "Mark Wood"]
    }
  ],
  "Audit and Incident Review Board (AIRB)": [
    {
      id: 1,
      startDate: "2024-04-12",
      eventName: "AIRB Meeting April 12, 2024",
      attendees: ["Mark Wood", "Lisa Audrey"]
    },
    {
      id: 2,
      startDate: "2024-05-15",
      eventName: "AIRB Meeting May 15, 2024",
      attendees: ["Ken Williams", "Olivia Perry", "Lisa Audrey", "Mark Wood", ]
    },
    {
      id: 3,
      startDate: "2024-06-24",
      eventName: "AIRB Meeting June 24, 2024",
      attendees: [ "Lisa Audrey", "Mark Wood" ]
    }
  ]
};

const renderTimelineEvents = (activeTab) => {
  const events = governanceBoardsData[activeTab];
  const sortedEvents = events.sort((a, b) => moment(b.startDate).diff(moment(a.startDate)));
  return sortedEvents;
};

const Governance = () => {
  const [activeTab, setActiveTab] = useState(Object.keys(governanceBoardsData)[0]);
  const [rgovernanceBoardsData, setRgovernanceBoardsData] = useState(governanceBoardsData);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editEvent, setEditEvent] = useState(null);

  // Jump to nearest upcoming event on page load
  useEffect(() => {
    jumpToNearestEvent();
  }, []);

  // Jump to nearest upcoming event on tab change
  const handleTabChange = (key) => {
    setActiveTab(Object.keys(rgovernanceBoardsData)[parseInt(key) - 1]);
    jumpToNearestEvent();
  };

  // Function to find and scroll to the nearest upcoming event
  const jumpToNearestEvent = () => {
    const nearestEvent = renderTimelineEvents(activeTab).find(event => moment(event.startDate).isAfter(moment(), 'day'));
    if (nearestEvent) {
      const element = document.getElementById(`timeline-item-${nearestEvent.id}`);
      if (element) {
        window.scrollTo(0, element.offsetTop);
      } 
    }
  };

  // Handlers for adding, editing, and deleting events
  const handleAddEvent = (values) => {
    const newEvent = {
      id: Date.now(),
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      eventName: values.eventName,
      attendees: values.attendees ? values.attendees : [],
    };
    governanceBoardsData[activeTab].push(newEvent);
    setRgovernanceBoardsData({...governanceBoardsData})
    setModalVisible(false);
    form.resetFields();
    message.success('Event added successfully');
  };

  const handleEditEvent = (values) => {
    const updatedEvent = {
      ...editEvent,
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      eventName: values.eventName,
      attendees: values.attendees ? values.attendees : [],
    };
    const updatedMeetings = governanceBoardsData[activeTab].map(meeting => {
      if (meeting.id === updatedEvent.id) {
        return updatedEvent;
      }
      return meeting;
    });
    governanceBoardsData[activeTab] = updatedMeetings;
    setModalVisible(false);
    form.resetFields();
    setEditEvent(null);
    setRgovernanceBoardsData({...governanceBoardsData})
    message.success('Event updated successfully');
  };

  const handleDeleteEvent = (meetingId) => {
    const updatedMeetings = governanceBoardsData[activeTab].filter(meeting => meeting.id !== meetingId);
    governanceBoardsData[activeTab] = updatedMeetings;
    setActiveTab(activeTab); // Force re-render by updating active tab
    setRgovernanceBoardsData({...governanceBoardsData})
    message.success('Event deleted successfully');

  };

  const showEditModal = (meeting) => {
    setEditEvent(meeting);
    setModalVisible(true);
    form.setFieldsValue({
      eventName: meeting.eventName,
      startDate: moment(meeting.startDate),
      attendees: meeting.attendees,
    });
  };

  const showModal = () => {
    setEditEvent(null);
    setModalVisible(true);
  };

  const closeModal = () => {
    setEditEvent(null);
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <Row className="governance-container">
      <Row className="bg-priamry font-black governance-header">
        <BreadcrumbComponent />
      </Row>
        <Tabs style={{ marginLeft: "25px" }} defaultActiveKey="1" onChange={handleTabChange} >
          {Object.keys(rgovernanceBoardsData).map((boardName, index) => (
            <TabPane tab={boardName} key={String(index + 1)}>
              <Button className='btn-primary' type="primary" style={{marginBottom: "15px"}} onClick={showModal}>Add Event</Button>
              <Timeline mode="left">
                {renderTimelineEvents(boardName).map((meeting, index) => (
                  <TimelineItem key={`timeline-item-${meeting.id}`}  color={moment(meeting.startDate).isBefore(moment(), 'day') ? 'gray' : undefined}>
                    <div>
                    <h3>{meeting.eventName}</h3>
                      <p><b>Meeting Link:</b> <a> meet.vigilia/{meeting.eventName} {moment(meeting.startDate).format('MMMM-DD,YYYY') }  </a></p>
                      <p><b>Attendees:</b> {meeting.attendees.join(', ')}</p>
                      <Button style={{marginRight: "5px"}} disabled={moment(meeting.startDate).isBefore(moment(), 'day')} icon={<EditOutlined />} onClick={() => showEditModal(meeting)}>Edit</Button>
                      <Button disabled={moment(meeting.startDate).isBefore(moment(), 'day')} icon={<DeleteOutlined />} onClick={() => handleDeleteEvent(meeting.id)}>Delete</Button>
                      {/* <Button disabled={moment(meeting.startDate).isBefore(moment(), 'day')}  onClick={() => handleDeleteEvent(meeting.id)}>Join</Button> */}
                    </div>
                  </TimelineItem>
                ))}
              </Timeline>
            </TabPane>
          ))}
        </Tabs>
        <Modal
          title={editEvent ? "Edit Event" : "Add Event"}
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          className='modal-audit-gov'
        >
          <Form
            form={form}
            onFinish={editEvent ? handleEditEvent : handleAddEvent}
          >
            <Form.Item
              label="Event Name"
              name="eventName"
              rules={[{ required: true, message: 'Please enter event name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: 'Please select start date' }]}
            >
              <Input type="date" />
            </Form.Item>
            <Form.Item
              label="Attendees"
              name="attendees"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
              >
                {attendeeList.map((attendee) => (
                  <Option key={attendee} value={attendee}>
                    {attendee}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit">{editEvent ? 'Update' : 'Add'}</Button>
          </Form>
        </Modal>
    </Row>
  )
}

export default Governance;
