// All Routes will be defined here
import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import NIST_CSF from "./pages/nistCsf";
import Dashboard from "./pages/installRunbook";
import Runbook from "./pages/runbook";
import Evaluate from "./pages/evaluate";
import Home from "./pages/home";
import Policy from "./pages/policy";
import Cves from "./pages/cves";
import RunbookDetail from "./pages/runbookDetail";
import CSF_CATEGORY from "./pages/csf_category"
import CsfSubcategory from "./pages/csf_subcategory"
import Control from "./pages/policy_control"
import Vendor from "./pages/vendor";
import PolicyCategory from "./pages/policiesCategory";
import PolicyDetail from "./pages/policiesCategoryDetail";
import TaskTemplate from "./pages/taskTemplate";

import PolicyDetailDescription from "./pages/policiesCategoryDetailDescription";
import Task from "./pages/task";
import TaskProcesses from "./pages/taskProcess";
import { taskMockData } from "../src/mockdata/mockJson"
import Governance from "./pages/governance";
import Audit from "./pages/audit";
const RoutesHandler = ({setUserName}) => {
  const navigate = useNavigate();
  const [tasks , setTasks] = useState(taskMockData)
  const StatusChanged = (newTasks , lasttaskstatus , taskid , newstatus)=>{
    
    taskMockData[lasttaskstatus]  = taskMockData[lasttaskstatus].filter(task=> !taskid.includes(task.taskid) )

    taskMockData[newstatus].push(...newTasks);
    setTasks( taskMockData)
    navigate("/task")
  }
  const formHandler = (values)=>{
    taskMockData[values.status].push(values)
    setTasks(taskMockData )
  }
  return (
    <Routes>
      <Route path="/installrunbook/:vendorcode" element={<Dashboard  />} />
      <Route path="/nist_csf" element={<NIST_CSF  />} />
      <Route path="/nist_csf/:categoryId/:subcategoryId" element={<CsfSubcategory  />} />
    
      <Route path="/nist_csf/:categoryId" element={<CSF_CATEGORY  />} />
      <Route path="/nist_csf/:categoryId/:subcategoryId/:id" element={<RunbookDetail  />} />
      <Route path="/nist_csf/:categoryId/:subcategoryId/policy/:policyDescriptionId" element={<PolicyDetailDescription  />} />
      <Route path="/runbookstore" element={<Vendor  pathname={"runbookstore"} isInstalled={false} />} />
      <Route path="/runbookstore/:vendorcode" element={<Runbook  />} />
      <Route path="/cves" element={<Cves  />} />
      <Route path="/policy" element={<Policy  />} />
      <Route
        path="/installrunbook/:vendorcode/:id"
        element={<RunbookDetail  />}
      />
      <Route
        path="/runbookstore/:vendorcode/:id"
        element={<RunbookDetail  />}
      />
      <Route
        path="/policy/:policyCategoryId/:policyDetailId/runbook/:id"
        element={<RunbookDetail  />}
      />
      <Route
        path="/task/:taskid/:id"
        element={<RunbookDetail  />}
      />
      <Route path="/" element={<Home />}  />
      <Route path="/installrunbook" element={<Vendor  pathname={"installrunbook"} isInstalled={true} />} />
      <Route path="/policy/:id" element={<PolicyCategory  />} />
      <Route path="/policy/:policyCategoryId/:policyDetailId" element={<PolicyDetail  />} />
      <Route path="/policy/:policyCategoryId/:policyDetailId/control/:controlId" element={<Control  />} />
      <Route path="/policy/:policyCategoryId/:policyDetailId/:policyDescriptionId" element={<PolicyDetailDescription  />} />
      <Route path="/task" element={<Task tasks={tasks}  formHandler={(values)=>formHandler(values)}  />} />
      <Route path="/task/:taskid" element={<TaskProcesses setUserName={setUserName}  StatusChanged={(tasks, lasttaskstatus , taskid , newstatus )=>StatusChanged(tasks ,lasttaskstatus , taskid , newstatus)}  />} />
      <Route path="/task/template" element={<TaskTemplate  />} />
      <Route path="/task/template/:id" element={<RunbookDetail  />} />
      <Route path="/governance" element={<Governance pathname={"installrunbook"} />} />
      <Route path="/audit" element={<Audit  />} />
      <Route path="/evaluate" element={<Evaluate  />} />

    </Routes>
  );
};

export default RoutesHandler;