import React from "react";
import "./index.css";

import Nistcsf from "../../component/nistCsf/nistcsf";
import { useEffect } from "react";
import { GetApi } from "../../service/http";
import { useState } from "react";

const Index = () => {
  const [data,setData ] = useState();
  const fetchData = async()=>{
    const result = await GetApi("get", `runbookService/api/v1/csf/stats?vendor`);
    setData(result)
  }
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line
  }, []);
  return <Nistcsf data={data} />;
};

export default Index;
