export function getColorByRange(value) {
  if (value === 0) {
    return "grey"; // theme.nist.zero
  } else if (value >= 1 && value < 70) {
    return "red"; // theme.nist.one_seventy
  } else if (value >= 70 && value < 80) {
    return "#F89D1C"; // theme.nist.seventy_eighty
  } else if (value >= 80 && value <= 100) {
    return "green"; // theme.nist.eighty_hundred
  } else {
    return "white"; // theme.nist.default
  }
}

//need to fix this once user api Integrate
export function getUserinfoByPath(currentPath, params) {
  if (currentPath.startsWith("/nist_csf")) {
    return { name: "Lisa Audrey", role: "CISO" };
  } else if (currentPath.startsWith("/runbookstore")) {
    return { name: "Mark Wood", role: "Admin" };
  }
  else if (currentPath.startsWith("/enterprise")) {
    return { name: "Mark Wood", role: "Admin" };
  }
  else if (currentPath.startsWith("/cves")) {
    return { name: "Lisa Audrey", role: "CISO" };
  } else if (currentPath.startsWith("/policy")) {
    return { name: "Lisa Audrey", role: "CISO" };
  } else if (currentPath === "/installrunbook") {
    return { name: "Mark Wood", role: "Admin" };
  } 
  else if (currentPath === "/home" || currentPath === "/" ) {
    return { name: "Lisa Audrey", role: "CISO" };
  }
  else if (currentPath.startsWith("/installrunbook")) {
    const pathSegments = currentPath.split("/");
    if (pathSegments.length > 3) {
      return { name: "Ken Williams", role: "Operator" };
    }
    return { name: "Mark Wood", role: "Admin" };
  } else if (currentPath.startsWith("/runbook")) {
    return { name: "Ken Williams", role: "Operator" };
  } else if (currentPath.startsWith("/task")) {
    return { name: "Ken Williams", role: "Operator" };
  } else if (currentPath.startsWith("/governance")) {
    return { name: "Lisa Audrey", role: "CISO" };
  } else if (currentPath.startsWith("/audit")) {
    return { name: "Lisa Audrey", role: "CISO" };
  }else if (currentPath.startsWith("/evaluate")) {
  return { name: "Lisa Audrey", role: "CISO" };
}else{
  return { name: "Lisa Audrey", role: "CISO" };
}
  
}

export function getBreadcrumbCategoryValue(value) {
  if (value === "DE") {
    return "Detect";
  } else if (value === "ID") {
    return "Identify";
  } else if (value === "PR") {
    return "Protect";
  } else if (value === "RC") {
    return "Recover";
  } else if (value === "RS") {
    return "Respond";
  }
}

export const getRoleByName = (name) => {
  if (name === "Ken Williams") {
    return "Operator";
  } else if (name === "Olivia Perry") {
    return "olivia";
  } else if (name === "Lisa Audrey") {
    return "CISO";
  } else if (name === "Mark Wood") {
    return "Admin";
  } else {
    return "Operator";
  }
};
