import React, { useEffect } from "react";
import PolicyCategory from "../../component/policyCategory/policyCategory";
import { useParams } from "react-router-dom";
import { GetApi } from "../../service/http";
import { useState } from "react";

const Index = () => {
  const [data,setData ] = useState();
  const params = useParams();
  //category api
  const fetchData = async()=>{
    const result = await GetApi("get", `runbookService/api/v1/policy?category=${params?.id}`);
    setData(result)
  }
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line
  }, []);
  return <PolicyCategory data={data} />;
};

export default Index;
