import React from "react";
import "./policyCategoryDetail.css";
import { Card, Col, Divider, Row, Popover, Empty } from "antd";
import {
  CheckCircleOutlined,
} from "@ant-design/icons";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBreadcrumbCategoryValue } from "../../utils";
const PolicyCategoryDetail = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryname = queryParams.get("categoryname");
  const policyCategoryname = queryParams.get("policyCategoryname");
  const policies = queryParams.get("policies");
  const policyCategoryValue = getBreadcrumbCategoryValue(
    params?.policyCategoryId?.split(".")[0]
  );
  const policyDetailDescriptoinHandler = (policyDescriptionId, policyname) => {
    navigate(
      `/policy/${params?.policyCategoryId}/${params?.policyDetailId}/${policyDescriptionId}?categoryname=${categoryname}&policyname=${policyname}`
    );
  };
  const runbookDetailHandler = (runbookId, installed) => {
    navigate(`/policy/${params?.policyCategoryId}/${params?.policyDetailId}/runbook/${runbookId}?isInstalled=${installed}&categoryname=${categoryname}`)
  }
  const controlhandler = (controlId ,controlname)=>{
    navigate(`/policy/${params?.policyCategoryId}/${params?.policyDetailId}/control/${controlId}?categoryname=${categoryname}&controlname=${controlname}`)
    
  }
  return (
    <div className="policy-detail-container">
      <Row className="bg-priamry font-black policy-detail-header">
        <BreadcrumbComponent categoryName={categoryname} />
      </Row>
      <Card
        title={
          <Row className="font-secondary">
            <Col
              align="left"
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              <p
                className={`summary-category category-options-${policyCategoryValue}`}
              >
                {policyCategoryValue}
              </p>
              <p className="font-sans text-sm">{categoryname}</p>
            </Col>
          </Row>
        }
        className="policy-category-card bg-secondary"
      >
        <Row className="font-default p-1">
          <Col span={18} align="left">
            <p className="text-sm  lg:text-xs">
              {params?.policyDetailId} :- {policyCategoryname}
            </p>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <p className="text-sm  lg:text-xs mr-3">{isNaN(policies)? 0: policies }</p>
            <span className="text-sm lg:text-xs">Policies</span>
          </Col>
        </Row>
        <Col style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
   
            <Row>
              <Col span={24} align="left">
                <p className="text-sm lg:text-xs mt-2 font-bold">Policies</p>
                <Divider />
                { data?.policies ? data?.policies?.map((policy, index) => (
                  <p
                    key={index}
                    className="text-sm  lg:text-xs"
                    style={{
                      lineHeight: "25px",
                      color: "rgb(1 100 127)",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      policyDetailDescriptoinHandler(policy.id, policy.name)
                    }
                  >
                    {policy.name}
                  </p>
                )) : <p>No Policies Found</p> }
              </Col>
            </Row> 
            <Row>
              <Col span={24} align="left">
                <p className="text-sm font-bold lg:text-xs">Controls</p>
                <Divider />
                {data?.controls ? data?.controls?.map((control, index) => (
                  <p
                    key={index}
                    className="text-sm  lg:text-xs"
                    style={{
                      cursor: "pointer", color: "rgb(1 100 127)" ,
                      lineHeight: "25px",
                    }}
                    onClick={()=>controlhandler(control?.id , control?.name)}
                  >
                    {control?.id}{" "}:{" "}{control.name}
                  </p>
                )) : <p>No Controls Found</p>}
              </Col>
            </Row>
            <Row>
              <Row style={{ width: "100%" }}>
                <Col span={12} align="left">
                  <p className="text-sm font-bold lg:text-xs">Runbooks</p>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <p className="text-sm font-bold lg:text-xs">Processes</p>
                </Col>
              </Row>
              <Divider />
              {data?.runbooks ?  data?.runbooks?.map((runbook, index) => (
                <Row key={index} style={{ width: "100%" }}>
                  <Col span={12} align="left">
                    <p
                      className="text-sm  lg:text-xs"
                      style={{ cursor: "pointer", color: "rgb(1 100 127)" }}
                      onClick={() => runbookDetailHandler(runbook?.runbookid, runbook?.installed)}
                    >
                      {runbook.runbookname}
                      {runbook?.installed &&  <Popover title="Installed ">
                        <CheckCircleOutlined
                          // title="Installed"
                          style={{
                            fontSize: "14px",
                            color: "green",
                            marginLeft: "5px"
                          }}
                        />
                      </Popover>
                      }
                    </p>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <p className="text-sm  lg:text-xs">{runbook.processes}</p>
                  </Col>
                </Row>
              ))  : <p>No Runbook Found</p>}
            </Row>
         
        </Col>
      </Card>
    </div>
  );
};

export default PolicyCategoryDetail;
