import React from "react";
import "./index.css";
import InstallRunbook from "../../component/installRunbook/installRunbook";
import { useEffect } from "react";
import { GetApi } from "../../service/http";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Index = () => {
  const params = useParams();
  const [data,setData ] = useState();
  const fetchData = async()=>{
    const result = await GetApi("get", `runbookService/api/v1/runbook?vendor=${params?.vendorcode}&category`);
    result.runbooks = result?.runbooks?.sort((a, b) => new Date(b.date) - new Date(a.date)) || [];
    setData(result)
  }
  const getApiHandler = ()=>{
    fetchData()
  }
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line
  }, []);
  return <InstallRunbook data={data} vendorcode={params?.vendorcode} getApiHandler={getApiHandler}/>;
};

export default Index;
