import React from "react";
import RunbookDetail from "../../component/runbookDetail/runbookDetail";

import { useState } from "react";

import "./index.css";

import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GetApi } from "../../service/http";
import { Layout, Row } from "antd";
import RightSider from "../../component/rightSider/rightSider";
const { Sider } = Layout;

const Index = () => {
  const [data, setData] = useState();
  const [scrollDirection, setScrollDirection] = useState(null);
  const [index, setIndex] = useState(-1);

  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const processnumber = queryParams.get("processnumber");
  useEffect(() => {
    if (processnumber) {
      const arr = processnumber?.split(".");
      const subtractedArray = arr.map((element) => {
        return parseInt(element, 10) - 1;
      });

      setIndex(subtractedArray);
    }
  }, [processnumber]);

  const handleScroll = () => {
    const container = document.getElementById("runbook-detail-container-id");
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    // Calculate scroll direction
    if (scrollTop > 0 && scrollTop < scrollHeight - clientHeight) {
      setScrollDirection(null);
    } else if (scrollTop === 0) {
      setScrollDirection("top");
    } else if (scrollTop === scrollHeight - clientHeight) {
      setScrollDirection("bottom");
    }
  };

  const handleScrollAction = () => {
    if (scrollDirection === "top" && index > 0) {
      setIndex((prevIndex) => prevIndex - 1);
    } else if (
      scrollDirection === "bottom" &&
      index < data?.runbook?.processes.length - 1
    ) {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  const fetchData = async () => {
    const result = await GetApi(
      "get",
      `runbookService/api/v1/runbook/${params?.id}`
    );

    result?.runbook?.processes?.splice(0, 0, {
      name: "Goal",
      goal: result?.runbook?.goal,
      impact: result?.runbook?.impact,
    });
    setData(result);
  };

  const outlineProcess = (index) => {
    setIndex(index);
  };
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line
  }, [index]);
  useEffect(() => {
    handleScrollAction();
    // eslint-disable-next-line
  }, [scrollDirection]);
  return (
    <div style={{ display: "flex" }}>
      <RunbookDetail data={data} index={index} handleScroll={handleScroll} />
      <Row style={{ width: "20%" }}>
        <Sider
          className="sider-primary sider right-sider"
          style={{ height: "92vh", position: "fixed", right: "0px" }}
        >
          <RightSider
            data={data}
            active={index}
            outlineProcess={(index) => outlineProcess(index)}
            runbookId={params?.id}
          />
        </Sider>
      </Row>
    </div>
  );
};

export default Index;
