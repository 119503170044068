import React from "react";
import PolicyCategoryDetailDescription from "../../component/policyCategoryDetailDescription/policyCategoryDetailDescription";
import { useParams } from "react-router-dom";
import { GetApi } from "../../service/http";
import { useEffect } from "react";
import { useState } from "react";
import { APIConfig } from "../../service/api"
const Index = () => {
  const [data, setData] = useState();
  const [uri, setURI] = useState('');
  const params = useParams();
  const url = `${APIConfig.baseURL()}/api/v1/policy?policy=${params?.policyDescriptionId}`
  const fetchData = async () => {
    const result = await GetApi("get", `runbookService/api/v1/policy?policy=${params?.policyDescriptionId}`);
    setData(result)
    setURI(`${APIConfig.baseURL()}/api/v1/policy?policy=${params?.policyDescriptionId}`)
  }
  useEffect(() => {
    // fetchData();
    // eslint-disable-next-line
  }, []);
  return <PolicyCategoryDetailDescription data={data} uri={url} />;
};

export default Index;
