import React, { useEffect, useState } from 'react'
import CsfCategory from '../../component/csf_category/csf_category'
import { GetApi } from '../../service/http';
import { useParams } from 'react-router-dom';

const Index = () => {
    const [data,setData ] = useState();
    const params = useParams();
    const fetchData = async()=>{
      const result = await GetApi("get", `runbookService/api/v1/csf/stats?vendor&category=${params?.categoryId}`);
      setData(result)
    }
    useEffect(() => {
      
      fetchData();
      // eslint-disable-next-line
    }, []);
  return (
   
    <CsfCategory data={data}/>
  )
}

export default Index
