import React, { useRef, useEffect } from "react";
import BreadcrumbComponent from "../breadcrumb/breadcrumb";
import "./runbookDetail.css";
import { Card, Col, Row, Select } from "antd";
import { useLocation, useParams } from "react-router-dom";
import Substep from "./substep";
import { Skeleton } from "antd";

const RunbookDetail = ({ data, index }) => {
  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);
  const categoryname = queryParams.get("categoryname");
  const category = queryParams.get("category");
  const taskname = queryParams.get("taskname");
  const taskstatus = queryParams.get("taskstatus");
  const step = queryParams.get("step");
  const stepRefs = useRef([]);
  let process;
  const automations = [
    "AWS CLI",
    "AWS Cloud formation",
    "AWS Systems Manager",
    "Manual",
  ];

  if (index !== -1) {
    process = data?.runbook?.runbookProcessList[index[0]];
    if (index.length > 1) {
      for (var i = 1; i < index.length; i++) {
        process = process?.subProcesses[index[i]];
      }
    }
  }

  function truncateString(str, maxWords) {
    const words = str?.trim()?.split(/\s+/);
    if (words?.length > maxWords) {
      return words?.slice(0, maxWords)?.join(' ') + '...';
    }
    return str;
  }

  useEffect(() => {
    if (step && data) {
      const stepIndex = parseInt(step, 10) - 1;
      if (stepRefs.current[stepIndex]) {
        stepRefs.current[stepIndex].scrollIntoView({ behavior: "smooth" });

        stepRefs.current[stepIndex]?.classList?.add("highlight");
        setTimeout(() => {
          stepRefs.current[stepIndex]?.classList?.remove("highlight");
        }, 7000);
      }
    }
  }, [step, data]);

  return (
    <>
      <div className="runbook-detail-container">
        {data ? (
          <Row className="bg-priamry font-black runbook-detail-header">
            <BreadcrumbComponent
              runBookName={data?.runbook?.title}
              category={category}
              categoryName={categoryname}
              taskname={truncateString(taskname, 3)}
              taskstatus={taskstatus}
            />
          </Row>
        ) : (
          <Skeleton active style={{ margin: "30px" }} />
        )}
        {data ? (
          <Row className="runbook-detail-card-container">
            <Card className="runbook-detail-card border-primary">
              <p className="text-3xl">{data?.counts?.processes}</p>
              <p className="text-lh">Processes</p>
            </Card>
            <Card className="runbook-detail-card border-primary">
              <p className="text-3xl">{data?.counts?.policies}</p>
              <p className="text-lh">NIST CSF Policies</p>
            </Card>
            <Card className="runbook-detail-card border-primary">
              <p className="text-3xl">{data?.counts?.subcategories}</p>
              <p className="text-lh">NIST CSF Sub-categories</p>
            </Card>
            <Card className="runbook-detail-card border-primary">
              <p className="text-3xl">{data?.counts?.controls}</p>
              <p className="text-lh">NIST 800-53 controls</p>
            </Card>
            {/* <Card className="runbook-detail-card border-primary">
              <p className="text-3xl" style={{ color: params.id === "22" ? "black" : "red" }}>
                {params.id === "22" ? 1 : 0}
              </p>
              <p className="text-lh">CVEs</p>
            </Card> */}
            {/* <Card className="runbook-detail-card border-primary">
              <p className="text-3xl">{params.id === "22" ? "100%" : "0%"}</p>
              <p className="text-lh">of Total CVEs</p>
            </Card> */}
          </Row>
        ) : (
          <Skeleton active style={{ margin: "30px" }} />
        )}
        {data ? (
          <Col className="border-primary mt-2 p-2" id="runbook-detail-container-id">
            <Row style={{ textAlign: "left" }}>
              <Col style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {index === -1 && (
                  <Row>
                    <p className="font-primary font-sans text-xl">
                      {data?.runbook?.title}
                    </p>
                  </Row>
                )}
                {index !== -1 && (
                  <Row>
                    <p className="font-primary font-sans text-xl">
                      {`${process?.processNumber}.`} {process?.processName}
                    </p>
                  </Row>
                )}
                {index === -1 && (
                  <>
                    <Row>
                      <p className="font-primary font-sans text-sm">Goal:</p>
                    </Row>
                    <Row>
                      <p className="font-default font-sans text-sm">
                        {data?.runbook?.goal}
                      </p>
                    </Row>
                    <Row>
                      <p className="font-primary font-sans text-sm">
                        Potential Impact of Failure:
                      </p>
                    </Row>
                    <Row>
                      <ul style={{ listStyle: "disc", marginLeft: "30px" }} className="font-default font-sans text-sm">
                        {data?.runbook?.runbookFailureImpact?.map(
                          (impact, index) => (
                            <li className="font-default font-sans text-sm" key={index}>
                              {impact}
                            </li>
                          )
                        )}
                      </ul>
                    </Row>
                  </>
                )}
                {index !== -1 && (
                  <>
                    {process?.purpose && (
                      <>
                        <Row>
                          <p className="font-primary font-sans text-sm">Purpose:</p>
                        </Row>
                        <Row>
                          <p className="font-default font-sans text-sm">{process?.purpose}</p>
                        </Row>
                      </>
                    )}
                    {process?.fips199SecurityCategorization && (
                      <>
                        <Row>
                          <p className="font-primary font-sans text-sm">FIPS 199 Security Categorization:</p>
                        </Row>
                        <Row>
                          <p className="font-default font-sans text-sm">{process?.fips199SecurityCategorization}</p>
                        </Row>
                      </>
                    )}
                    {process?.processFailureImpact?.length > 0 && (
                      <>
                        <Row>
                          <p className="font-primary font-sans text-sm">Potential Impact of Failure:</p>
                        </Row>
                        <Row>
                          <ul style={{ listStyle: "disc", marginLeft: "30px" }} className="font-default font-sans text-sm">
                            {process.processFailureImpact?.map((impact, index) => (
                              <li className="font-default font-sans text-sm" key={index}>
                                {impact}
                              </li>
                            ))}
                          </ul>
                        </Row>
                      </>
                    )}
                    {process?.nistCsfSubcategories?.length > 0 && (
                      <>
                        <Row>
                          <p className="font-primary font-sans text-sm">NIST CSF 1.1 Subcategories:</p>
                        </Row>
                        <Row>
                          <ul style={{ listStyle: "disc", marginLeft: "30px" }} className="font-default font-sans text-sm">
                            {process.nistCsfSubcategories?.map((subcategory, index) => (
                              <li className="font-default font-sans text-sm" key={index}>
                                {subcategory}
                              </li>
                            ))}
                          </ul>
                        </Row>
                      </>
                    )}
                    {process?.nist80053Controls?.length > 0 && (
                      <>
                        <Row>
                          <p className="font-primary font-sans text-sm">NIST 800-53r5 Controls:</p>
                        </Row>
                        <Row>
                          <ul style={{ listStyle: "disc", marginLeft: "30px" }} className="font-default font-sans text-sm">
                            {process.nist80053Controls?.map((control, index) => (
                              <li className="font-default font-sans text-sm" key={index}>
                                {control}
                              </li>
                            ))}
                          </ul>
                        </Row>
                      </>
                    )}
                    {process?.dependencies && (
                      <>
                        <Row>
                          <p className="font-primary font-sans text-sm">Dependencies:</p>
                        </Row>
                        <Row>
                          <p className="font-default font-sans text-sm">{process?.dependencies}</p>
                        </Row>
                      </>
                    )}
                    <Row style={{ gap: "10px", alignItems: "center" }}>
                      <p className="font-primary font-sans text-sm">Procedural Steps:</p>
                    </Row>
                    {process?.proceduralSteps?.length > 0 ? (
                      <Row style={{ textAlign: "left" }}>
                        {process?.proceduralSteps?.map((step, index) => (
                          <Col
                            key={index}
                            ref={(el) => (stepRefs.current[index] = el)}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              width: "100%",
                            }}
                            className="procedural-step"
                          >
                            <Row style={{ gap: "10px", alignItems: "center" }}>
                              <p className="font-sans font-primary">
                                {index + 1} : {step?.description}
                              </p>
                              <Select placeholder="Select Automation">
                                {automations?.map((automation, index) => (
                                  <Select.Option key={index + 1} value={automation}>
                                    {automation}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Row>
                            <ul
                              style={{
                                marginLeft: "30px",
                                marginBottom: "20px",
                                listStyle: "lower-alpha",
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              {step?.stepList?.map((steplist, index) => (
                                <Substep substep={steplist} index={index + 1} isChild={false} />
                              ))}
                            </ul>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <Row>
                        <p className="font-default">Refer subsequent sections of the Runbook</p>
                      </Row>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        ) : (
          <Skeleton active style={{ margin: "30px" }} />
        )}
      </div>
    </>
  );
};

export default RunbookDetail;
