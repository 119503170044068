import React from "react";
import "./index.css";

import RunbookStore from "../../component/runbookStore/runbookStore";
import { useEffect } from "react";
import { GetApi } from "../../service/http";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Index = () => {
  const params = useParams();

  const [data, setData] = useState();
  const [categoryData, setCategoryData] = useState();
  const fetchData = async () => {
    const result = await GetApi(
      "get",
      `runbookService/api/v1/store?vendor=${params?.vendorcode}&category`
    );
    const categoryresult = await GetApi(
      "get",
      `runbookService/api/v1/categories`
    );
    result.runbooks = result?.runbooks?.sort((a, b) => new Date(b.date) - new Date(a.date)) || [];
    setData(result);
    setCategoryData(categoryresult);
  };
  const categoryFilter = async (value) => {
    const result = await GetApi(
      "get",
      `runbookService/api/v1/store?vendor=${params?.vendorcode}&category=${value}`
    );
    result.runbooks = result?.runbooks?.sort((a, b) => new Date(b.date) - new Date(a.date)) || [];
    
    setData(result);
  };
  const getApiHandler = () => {
    fetchData();
  };
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line
  },[]);
  return (
    <RunbookStore
      data={data}
      categoryData={categoryData}
      categoryFilter={(value) => categoryFilter(value)}
      getApiHandler={getApiHandler}
    />
  );
};

export default Index;
