import React from "react";

import { Button, Form, Select } from "antd";

const ModalContent = ({ automationFinishHanlder }) => {
  const automations = ["AWS CLI", "AWS Cloud formation", "AWS Systems Manager"];
  const onFinish = (values) => {
    automationFinishHanlder(values.automation)
  };
  return (
    <Form onFinish={onFinish} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Form.Item name="automation" rules={[{ required: true, message: 'Please select an automation' }]}>
        <Select placeholder="Select Automations" style={{ width: "100%" }}>
          {automations?.map((automation, index) => (
            <Select.Option key={index + 1} value={automation}>
              {automation}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Button
        className="btn-primary"
        style={{ width: "100%" }}
        type="primary"
        htmlType="submit"
      >
        Add
      </Button>
    </Form>
  );
};

export default ModalContent;
