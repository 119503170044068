import Audit from '../../component/audit/audit';
import React from 'react'

const Index = () => {
 
  return (
  <Audit/>
  )
}

export default Index
