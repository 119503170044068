import React, { useState } from 'react';
import { Table, Button, Popover, Form, Input, Modal, Row, Select, Typography, message } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import BreadcrumbComponent from '../breadcrumb/breadcrumb'; // Make sure to have the BreadcrumbComponent
import "./evaluate.css";

const { Text } = Typography;
const { Option } = Select;

const Evaluate = () => {
  const initialServices = [
    { key: 1, name: 'AWS S3', cves: 0, oevs: 225, hrcLevel: 3 },
    { key: 2, name: 'AWS EC2', cves: 1, oevs: 121, hrcLevel: 2 },
    { key: 3, name: 'AWS IAM', cves: 0, oevs: 212, hrcLevel: 1 },
    { key: 4, name: 'AWS Redshift', cves: 0, oevs: 112, hrcLevel: 2 },
    { key: 5, name: 'AWS Lambda', cves: 0, oevs: 128, hrcLevel: 2 },
    { key: 6, name: 'AWS RDS', cves: 0, oevs: 279, hrcLevel: 2 },
    { key: 7, name: 'AWS DynamoDB', cves: 0, oevs: 196, hrcLevel: 1 },
    { key: 8, name: 'AWS VPC', cves: 0, oevs: 102, hrcLevel: 1 },
    { key: 9, name: 'AWS CloudWatch', cves: 0, oevs: 192, hrcLevel: 2 },
    { key: 10, name: 'AWS Elastic Beanstalk', cves: 0, oevs: 160, hrcLevel: 2 },
  ];

  const [services, setServices] = useState(initialServices);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showAddServiceModal = () => {
    setIsModalVisible(true);
  };

  const handleAddService = () => {
    form.validateFields().then((values) => {
      const existingService = services.some(service => service.name.toLowerCase() === values.name.toLowerCase());
      if (!existingService) {
        const newService = {
          ...values,
          key: services.length + 1,
          cves: parseInt(values.cves),
          oevs: parseInt(values.oevs),
          hrcLevel: parseInt(values.hrcLevel)
        };
        setServices([...services, newService]);
        setIsModalVisible(false);
        form.resetFields();
      } else {
        form.setFields([{ name: 'name', errors: ['Service name already exists'] }]);
      }
    }).catch((errorInfo) => {
      message.error('Please fill out all required fields correctly.');
    });
  };

  const columns = [
    {
      title: (
        <span>
          My Cloud Services
          <Popover
            content={<Button type="primary" icon={<PlusOutlined />} onClick={showAddServiceModal}>Add New Service</Button>}
          >
            <PlusOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
          </Popover>
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CVEs',
      dataIndex: 'cves',
      key: 'cves',
      width: 100,
      align: 'center',
    },
    {
      title: 'OEVs',
      dataIndex: 'oevs',
      key: 'oevs',
      width: 100,
      align: 'center',
    },
    {
      title: 'Operational Profile',
      key: 'operationalProfile',
      width: 200,
      render: () => <Button disabled>Operational Profile</Button>,
      align: 'center',
    },
    {
      title: 'Highest Recommended HRC Level',
      key: 'hrcLevel',
      width: 300,
      render: (_, record) => (
        <Popover
          content={
            <div>
              <p><Text className={record.hrcLevel === 0 ? `active-hrc` : ""} strong={record.hrcLevel === 0}>0 - No HRC</Text></p>
              <p><Text className={record.hrcLevel === 1 ? `active-hrc` : ""} strong={record.hrcLevel === 1}>1 - HRC Low:</Text> Operator executes the Tasks & Reviews them, no external Reviewer</p>
              <p><Text className={record.hrcLevel === 2 ? `active-hrc` : ""} strong={record.hrcLevel === 2}>2 - HRC High:</Text> Active Backup, Operator executes the Tasks & Reviewer reviews after the task is completed</p>
              <p><Text className={record.hrcLevel === 3 ? `active-hrc` : ""} strong={record.hrcLevel === 3}>3 - HRC Very High:</Text> Operator executes the Tasks & Reviewer reviews at the same time, not after the fact</p>
            </div>
          }
        >
          <span>
            {record.hrcLevel} {" "} <InfoCircleOutlined style={{ marginLeft: "5px", cursor: "pointer" }} />
          </span>
        </Popover>
      ),
      align: 'center',
    },
  ];

  return (
    <div className="tasks-container">
      <Row className="bg-primary font-black tasks-header-eval">
        <BreadcrumbComponent />
      </Row>
      <div style={{ marginTop: '25px', paddingRight: "35px" }}>
        <Table columns={columns} dataSource={services} pagination={false} />
      </div>
      <Modal
        title="Add New Service"
        visible={isModalVisible}
        className='eval-modal'
        onOk={handleAddService}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Service Name"
            rules={[{ required: true, message: 'Please enter the service name' }]}
          >
            <Input placeholder="Enter Service Name" />
          </Form.Item>
          <Form.Item
            name="cves"
            label="CVEs"
            rules={[{ required: true, message: 'Please enter the number of CVEs' }]}
          >
            <Input placeholder="Enter CVEs" type="number" min={0} />
          </Form.Item>
          <Form.Item
            name="oevs"
            label="OEVs"
            rules={[{ required: true, message: 'Please enter the number of OEVs' }]}
          >
            <Input placeholder="Enter OEVs" type="number" min={0} />
          </Form.Item>
          <Form.Item
            name="hrcLevel"
            label="HRC Level"
            rules={[{ required: true, message: 'Please select the HRC Level' }]}
          >
            <Select placeholder="Select HRC Level">
              <Option value="0">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>0</span>
                  <span style={{ marginLeft: '7px' }}>- No HRC</span>
                </span>
              </Option>
              <Option value="1">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>1</span>
                  <span style={{ marginLeft: '7px' }}>- HRC Low</span>
                </span>
              </Option>
              <Option value="2">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>2</span>
                  <span style={{ marginLeft: '7px' }}>- HRC High</span>
                </span>
              </Option>
              <Option value="3">
                <span style={{ display: 'inline-block' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'left' }}>3</span>
                  <span style={{ marginLeft: '7px' }}>- HRC Very High</span>
                </span>
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Evaluate;
