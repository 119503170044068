import React from "react";
import { Card, Col, Row } from "antd";
import "./nistcsf.css";
import { getColorByRange } from "../../utils";
import "./v1.1.css";
import { useNavigate } from "react-router-dom";
const V1_1NIST = ({ data, vendorData }) => {
  const customOrder = ["Identify", "Protect", "Detect", "Respond", "Recover"];
  const navigate = useNavigate();
  const mockData = Object.keys(data?.stats || {}).sort((a, b) => {
    return customOrder.indexOf(a) - customOrder.indexOf(b);
  });

  const calculatePoliciesSum = (category) => {
    return data?.stats[category].reduce(
      (sum, entry) => sum + entry.policies,
      0
    );
  };
  const calculateRunbooksSum = (category) => {
    return data?.stats[category].reduce(
      (sum, entry) => sum + entry.processes,
      0
    );
  };
  const calculateAverageCompliance = (category) => {
    const totalEntries = data?.stats[category].length;
    const totalCompliance = data?.stats[category].reduce(
      (sum, entry) => sum + entry.compliance,
      0
    );

    return totalEntries > 0 ? totalCompliance / totalEntries : 0;
  };
  const csfCategoryHandler = (categoryId, category, categoryName) => {
    navigate(
      `/nist_csf/${categoryId}?category=${category}&categoryName=${categoryName}`
    );
  };
  const sortVendorArr = vendorData?.vendors?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div>
      <Row className="vendor-runbook-container">
        {sortVendorArr?.map((vendor, index) => (
          <>
            {vendor?.runbooks > 0 && (
              <Card className="vendor-runbook border-primary">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p className="text-lh" style={{ fontWeight: "bold" }}>
                      Vendor:
                    </p>
                    <p className="text-lh"> {vendor?.name}</p>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p className="text-lh" style={{ fontWeight: "bold" }}>
                      Runbooks:{" "}
                    </p>
                    <p className="text-lh"> {vendor?.runbooks || "0"} </p>
                  </div>
                </div>
              </Card>
            )}
          </>
        ))}

        {false && (
          <>
            {" "}
            <Card className="vendor-runbook border-primary">
              <Row>
                <p className="text-lh">Vendor:</p>
                <p className="text-lh"> {"GCP"}</p>
              </Row>
              <Row>
                <p className="text-lh">Runbooks: </p>
                <p className="text-lh"> {"0"} </p>
              </Row>
            </Card>
            <Card className="vendor-runbook border-primary">
              <Row>
                <p className="text-lh">Vendor:</p>
                <p className="text-lh"> {"AZ"}</p>
              </Row>
              <Row>
                <p className="text-lh">Runbooks: </p>
                <p className="text-lh"> {"0"} </p>
              </Row>
            </Card>
            <Card className="vendor-runbook border-primary">
              <Row>
                <p className="text-lh">Vendor:</p>
                <p className="text-lh"> {"OCI"}</p>
              </Row>
              <Row>
                <p className="text-lh">Runbooks: </p>
                <p className="text-lh"> {"0"} </p>
              </Row>
            </Card>
          </>
        )}
      </Row>
      <Row className="nist-card-container">
        {mockData.map((category, index) => (
          <Card
            key={index}
            title={
              <Row className="font-secondary">
                <Col
                  xl={10}
                  lg={8}
                  md={6}
                  xs={6}
                  sm={6}
                  align="left"
                  className=""
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p
                    className={`summary-category category-options-${category}`}
                  >
                    {category}
                  </p>
                </Col>
                <Col xl={14} lg={16} md={18} xs={18} sm={18}>
                  <Row>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "0px",
                          flexDirection: "column",
                        }}
                      >
                        <p className="text-sm lg:text-xs">
                          {calculatePoliciesSum(category)}
                        </p>
                        <span className="text-sm lg:text-xs">Policies</span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "0px",
                          flexDirection: "column",
                        }}
                      >
                        <p className="text-sm lg:text-xs">
                          {calculateRunbooksSum(category)}{" "}
                        </p>
                        <span className="text-sm lg:text-xs">Processes</span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "0px",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          className="text-sm lg:text-xs"
                          style={{
                            color: getColorByRange(
                              calculateAverageCompliance(category)
                            ),
                          }}
                        >
                          {calculateAverageCompliance(category)}%{" "}
                        </p>
                        <span
                          style={{
                            color: getColorByRange(
                              calculateAverageCompliance(category)
                            ),
                          }}
                          className="text-sm lg:text-xs"
                        >
                          Compliance
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            className="nist-card bg-secondary"
          >
            {data?.stats[category]?.map((data, index) => (
              <Row key={index} className="font-default p-1">
                <Col xl={10} lg={8} md={6} xs={6} sm={6} align="left">
                  <p
                    className="text-sm  lh-1 lg:text-xs"
                    style={{ cursor: "pointer", color: "rgb(1 100 127)" }}
                    onClick={() =>
                      csfCategoryHandler(
                        data?.categoryid,
                        category,
                        data?.categoryname
                      )
                    }
                  >
                    {data.categoryname}
                  </p>
                </Col>
                <Col xl={14} lg={16} md={18} xs={18} sm={18} align="center">
                  <Row>
                    <Col span={8}>
                      <p className="text-sm lg:text-xs">{data.policies} </p>
                    </Col>
                    <Col span={8}>
                      <p className="text-sm lg:text-xs">{data.processes} </p>
                    </Col>
                    <Col span={8}>
                      <p
                        className="text-sm lg:text-xs"
                        style={{ color: getColorByRange(data?.compliance) }}
                      >
                        {data?.Compliance === 0 ? "NA" : `${data?.compliance}%`}{" "}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </Card>
        ))}
      </Row>
    </div>
  );
};

export default V1_1NIST;
