import React, { useState } from 'react';
import './threedotmenu.css'
import { Menu } from 'antd';
const ThreeDotsMenu = ({ selectedStatus, StatusChangeHandler ,isReviewer}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuopenHandler = () => {

    setMenuOpen(!menuOpen);
  };

  const handleStatusChange = (status) => {

    setMenuOpen(false);
    StatusChangeHandler(status)
  };

  return (
    <div className="three-dots-menu">
      <div className="menu-icon" onClick={menuopenHandler} >
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
      {menuOpen && (
        <div>
          <Menu mode="vertical" className="menu">
            <Menu.SubMenu className='sub-menu-task' key="move" title="Move">
              <Menu.Item
                key="todo"
                className={selectedStatus === 'todo' ? 'selected' : ''}
                onClick={() => handleStatusChange('todo')}
              >
              <p className='text-sm'>  To Do</p>
              </Menu.Item>
              <Menu.Item
                key="inProgress"
                className={selectedStatus === 'inProgress' ? 'selected' : ''}
                onClick={() => handleStatusChange('inProgress')}
              >
              <p className='text-sm'>  In Progress</p>
              </Menu.Item>
              <Menu.Item
                key="inReview"
                disabled={!isReviewer}
                className={selectedStatus === 'inReview' ? 'selected' : ''}
                onClick={() => handleStatusChange('inReview')}
              >
               <p className='text-sm'>In Review </p>
              </Menu.Item>
              <Menu.Item
                key="done"
                className={selectedStatus === 'done' ? 'selected' : ''}
                onClick={() => handleStatusChange('done')}
              >
                <p className='text-sm'>Done</p>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="delete" disabled={true} title="Delete">
                <p className='text-sm'>Delete</p>
            </Menu.Item>
            <Menu.Item disabled={true} key="archived" title="Archived">
             <p className='text-sm'>Archive</p>
            </Menu.Item>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default ThreeDotsMenu;
