import { Empty, Row, Modal, Form, Input, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../breadcrumb/breadcrumb';
import './audit.css';
import moment from 'moment';
import { Tabs, Button, Timeline, message } from 'antd';
import { CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;
const { Item: TimelineItem } = Timeline;
const { Option } = Select;

const attendeeList = [
  "Ken Williams",
  "Olivia Perry",
  "Lisa Audrey",
  "Mark Wood",
];
const governanceBoardsData = {
  "Cybersecurity Maturity Model Certification (CMMC)": [
    {
      id: 1,
      startDate: "2024-04-20",
      eventName: "CMMC Meeting April 20, 2024",
      attendees: ["Ken Williams", "Olivia Perry", "Lisa Audrey"],
      auditor: "Random Firm Inc"
    },
    {
      id: 2,
      startDate: "2024-04-25",
      eventName: "CMMC Meeting April 25, 2024",
      attendees: ["Ken Williams",  "Lisa Audrey", "Mark Wood"],
      auditor: "Audit Co"
    },
    {
      id: 3,
      startDate: "2024-05-30",
      eventName: "CRB Meeting May 30, 2024",
      attendees: ["Ken Williams",  "Lisa Audrey"],
      auditor: "Independent Auditor Ltd"
    },
    {
      id: 4,
      startDate: "2024-06-30",
      eventName: "CRB Meeting June 30, 2024",
      attendees: [  "Lisa Audrey","Mark Wood",  "Olivia Perry"],
      auditor: "Independent Auditor Ltd"
    }
  ],
  "High Reliability Security (HRC)": [
    {
      id: 1,
      startDate: "2024-04-12",
      eventName: "HRC Meeting April 12, 2024",
      attendees: ["Mark Wood", "Lisa Audrey"],
      auditor: "Audit World"
    },
    {
      id: 2,
      startDate: "2024-05-24",
      eventName: "HRC Meeting May 12, 2024",
      attendees: ["Ken Williams", "Olivia Perry", "Lisa Audrey", "Mark Wood"],
      auditor: "Global Auditing"
    },
    {
      id: 3,
      startDate: "2024-06-27",
      eventName: "HRC Meeting June 27, 2024",
      attendees: ["Olivia Perry", "Lisa Audrey", "Mark Wood"],
      auditor: "Global Auditing"
    }
  ],
  "Incidents" : {
    id: 3,
    noData: true
  },
  "Corrective Actions" : {
    id: 4,
    noData: true
  },
  "Lessons Learned" : {
    id: 5,
    noData: true
  }
};

const renderTimelineEvents = (activeTab) => {
  const events = governanceBoardsData[activeTab];
  events.sort((a, b) => moment(b.startDate).diff(moment(a.startDate)));
  return events;
};

const Audit = () => {
  const [activeTab, setActiveTab] = useState(Object.keys(governanceBoardsData)[0]);
  const [rgovernanceBoardsData, setRgovernanceBoardsData] = useState(governanceBoardsData);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editEvent, setEditEvent] = useState(null);

  const handleTabChange = (key) => {
    setActiveTab(Object.keys(rgovernanceBoardsData)[parseInt(key) - 1]);
  }

  const handleAddEvent = (values) => {
    const newEvent = {
      id: Date.now(),
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      eventName: values.eventName,
      attendees: values.attendees ? values.attendees : [],
      auditor: values.auditor
    };
    governanceBoardsData[activeTab].push(newEvent);
    setRgovernanceBoardsData({...governanceBoardsData})
    setModalVisible(false);
    form.resetFields();
    message.success('Event added successfully');
  };

  const handleEditEvent = (values) => {
    const updatedEvent = {
      ...editEvent,
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      eventName: values.eventName,
      attendees: values.attendees ? values.attendees : [],
      auditor: values.auditor
    };
    const updatedMeetings = governanceBoardsData[activeTab].map(meeting => {
      if (meeting.id === updatedEvent.id) {
        return updatedEvent;
      }
      return meeting;
    });
    governanceBoardsData[activeTab] = updatedMeetings;
    setModalVisible(false);
    form.resetFields();
    setEditEvent(null);
    setRgovernanceBoardsData({...governanceBoardsData})
    message.success('Event updated successfully');
  };

  const handleDeleteEvent = (meetingId) => {
    const updatedMeetings = governanceBoardsData[activeTab].filter(meeting => meeting.id !== meetingId);
    governanceBoardsData[activeTab] = updatedMeetings;
    setActiveTab(activeTab);
    setRgovernanceBoardsData({...governanceBoardsData})
    message.success('Event deleted successfully');
  };

  const showEditModal = (meeting) => {
    setEditEvent(meeting);
    setModalVisible(true);
    form.setFieldsValue({
      eventName: meeting.eventName,
      startDate: moment(meeting.startDate),
      attendees: meeting.attendees,
      auditor: meeting.auditor
    });
  };

  const showModal = () => {
    setEditEvent(null);
    setModalVisible(true);
  };

  const closeModal = () => {
    setEditEvent(null);
    setModalVisible(false);
    form.resetFields();
  };

  return (
    <Row className="governance-container">
      <Row className="bg-priamry font-black governance-header">
        <BreadcrumbComponent />
      </Row>
      <Tabs style={{ marginLeft: "25px" }} defaultActiveKey="1" onChange={handleTabChange} >
        {Object.keys(rgovernanceBoardsData).map((boardName, index) => (
          <TabPane tab={boardName} key={String(index + 1)}>
            {!governanceBoardsData[boardName].noData && <Button className='btn-primary' type="primary" style={{marginBottom: "15px"}} onClick={showModal}>Add Event</Button>}
            {!governanceBoardsData[boardName].noData ? (
              <Timeline mode="left">
                {renderTimelineEvents(boardName).map((meeting, index) => (
                  <TimelineItem key={`timeline-item-${meeting.id}`}  color={moment(meeting.startDate).isBefore(moment(), 'day') ? 'gray' : undefined}>
                    <div>
                      <h3>{meeting.eventName}</h3>
                      <p><b>Meeting Link:</b> <a> meet.vigilia/{meeting.eventName} {moment(meeting.startDate).format('MMMM-DD,YYYY') }</a></p>
                      <p><b>Attendees:</b> {meeting.attendees.join(', ')}</p>
                      {meeting.auditor && <p><b>Auditor:</b> {meeting.auditor}</p>}
                      <Button style={{marginRight: "5px"}} disabled={moment(meeting.startDate).isBefore(moment(), 'day')} icon={<EditOutlined />} onClick={() => showEditModal(meeting)}>Edit</Button>
                      <Button disabled={moment(meeting.startDate).isBefore(moment(), 'day')} icon={<DeleteOutlined />} onClick={() => handleDeleteEvent(meeting.id)}>Delete</Button>
                    </div>
                  </TimelineItem>
                ))}
              </Timeline>
            ) : (
              <Empty description={"Coming Soon"} />
            )}
          </TabPane>
        ))}
      </Tabs>
      <Modal
        title={editEvent ? "Edit Event" : "Add Event"}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        className='modal-audit-gov'
      >
        <Form
          form={form}
          onFinish={editEvent ? handleEditEvent : handleAddEvent}
        >
          <Form.Item
            label="Event Name"
            name="eventName"
            rules={[{ required: true, message: 'Please enter event name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true, message: 'Please select start date' }]}
          >
            <Input type="date" />
          </Form.Item>
          <Form.Item
            label="Attendees"
            name="attendees"
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {attendeeList.map((attendee) => (
                <Option key={attendee} value={attendee}>
                  {attendee}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Auditor"
            name="auditor"
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">{editEvent ? 'Update' : 'Add'}</Button>
        </Form>
      </Modal>
    </Row>
  )
}

export default Audit;
