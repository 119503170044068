import React, { useEffect, useState } from 'react'
import CsfSubcategory from '../../component/csf_subcategory/csf_subcategory'
import { GetApi } from '../../service/http';
import { useParams } from 'react-router-dom';

const Index = () => {

    const [data,setData ] = useState();
    const params = useParams();
    const fetchData = async()=>{
      const result = await GetApi("get", `runbookService/api/v1/csf/stats?vendor&subcategory=${params?.subcategoryId}`);
      setData(result)
    }
    useEffect(() => {
      
      fetchData();
      // eslint-disable-next-line
    }, []);
  return (
   
    <CsfSubcategory data={data}/>
  )
}

export default Index
