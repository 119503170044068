import React, { useState } from "react";

import "./rightSider.css";
import { Button, Modal, Row, notification, Select, Checkbox, Dropdown } from "antd";
import { MethodApi } from "../../service/http";
import { useLocation, useNavigate } from "react-router-dom";
import SubProcessChild from "./subProcessChild";
import { Skeleton } from "antd";

const RightSider = ({
  data,
  outlineProcess,
  active,
  activePanel,
  runbookId,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isInstalled = queryParams.get("isInstalled");

  const resilencylist = [
    {
      label: <Checkbox defaultChecked={true} disabled={true}>Low</Checkbox>,
      key: "1",
    },
    {
      label: <Checkbox defaultChecked={true} disabled={true}>Medium</Checkbox>,
      key: "2",
    },
    {
      label: <Checkbox defaultChecked={true} disabled={true}>High</Checkbox>,
      key: "3",
    },
  ];

  const resilencyProps = {
    items: resilencylist,
  };

  const outlineHanlder = (index) => {

    outlineProcess(index);
    const params = new URLSearchParams(location.search);
    params.delete('step');
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    }, { replace: true });
  };
  const deleteHandler = () => {
    Modal.confirm({
      title: "Confirm Uninstallation",
      content: "Are you sure you want to uninstall this runbook?",
      onOk() {
        setLoading(true);
        MethodApi({
          method: "delete",
          pathname: `runbookService/api/v1/runbook/${runbookId}`,
          onSuccess: () => {
            setTimeout(() => {
              setLoading(false);
            }, 2000);
            api["success"]({
              message: "Uninstalled Successfully",
              duration: 2,
            });

            navigate("/installrunbook");
            window.location.reload();
          },
          onError: (error) => {
            api["error"]({
              message: error?.message || "Uninstall Runbook failed",
              duration: 2,
            });
          },
        });
      },
      onCancel() { },
    });
  };
  const installRunbookHandler = async () => {
    await MethodApi({
      method: "put",
      pathname: `runbookService/api/v1/store/${runbookId}`,
      onSuccess: () => {
        notification.success({
          message: "Runbook Installed",
          duration: 10,
        });
        navigate("/installrunbook");
        window.location.reload();
      },
      onError: (error) => {
        notification.error({
          message: error?.message || "Install Runbook failed",
          duration: 2,
        });
      },
    });
  };
  const isRunbookStorePath = location.pathname.includes("/runbookstore");
  return (
    <div className="right-sider-container">
      {contextHolder}
      <div className="p-2 text-left">
        <p className="font-sans text-left font-primary">
          {data?.runbook?.title}
        </p>
        <p>{data?.runbook?.version}</p>
        <p>{data?.runbook?.date}</p>
      </div>
      {
        <Button 
        disabled={isRunbookStorePath || data?.runbook?.title?.toLowerCase() !== "AWS S3 HRO Runbook".toLowerCase()}
        onClick={()=>{
          navigate("/task?tab=2&templateId=1001")
        }} type="primary" className="btn-primary btn-cves-category">
          <p className="text-sm">Create Task Template</p>{" "}
        </Button>
}

      <p className="pl-2" style={{ textAlign: "left", fontWeight: "bold" }}>
        Outline
      </p>
      <hr />

      {data ? <Row
        style={{ padding: "10px", cursor: "pointer", borderRadius: "8px" }}
        className={`border-primary  ${active === -1 ? "active" : ""}`}
        onClick={() => outlineHanlder(-1, -1)}
      >
        <p className="font-default font-sans">Goal</p>
      </Row> : (
        <Skeleton active style={{ margin: "30px" }} />
      )}

      <p
        className="font-default font-sans pl-2"
        style={{ textAlign: "left", fontWeight: "bold" }}
      >
        Processes
      </p>

      {data ? <Row className="right-sider-outline-parent">
        {data?.runbook?.runbookProcessList?.map((data, index) => {
          return (
            <SubProcessChild
              key={index}
              subprocess={data}
              index={[index]}
              active={active}
              activePanel={activePanel}
              outlineHanlder={outlineHanlder}
            />
          );
        })}
      </Row> : (
        <Skeleton active style={{ margin: "30px" }} />
      )}
      {data ? <Button
        className="right-sider-delete-btn btn-primary"
        loading={loading}
        onClick={isInstalled === "true" ? deleteHandler : installRunbookHandler}
      >
        {isInstalled === "true" ? `Uninstall` : `Install`}
      </Button> : (
        <Skeleton.Button active style={{ margin: "30px" }} />
      )}
    </div>
  );
};

export default RightSider;
